export const constants = {
  buttonLabel: 'Generate Data',
  generatePentestData: 'Generate Pentest Data',
  title: 'There’s nothing here yet.',
  description: 'You can start your set by uploading a CSV file,\n or we can help you generate it automatically.',
  uploadImgAlt: 'generate illustration',
  generateImgAlt: 'generate illustration',
  generateEvaluationData: 'Generate Evaluation Data',
  dialogDescription: 'Generate additional user inputs automatically to get to a meaningful evaluation.',
  submitButtonLabel: 'Generate',
  selectCategories: 'Select categories  to proceed:',
  searchCategories: 'Search categories',
  selectAll: (count: number) => `Select All (${count})`,
  deselectAll: 'Deselect All',
  nextBtnLabel: (count: number) => `Next ${count > 0 ? `(${count})` : ''}`,
  generatePentestDescription:
    'Each category includes one or more inputs to run your application on, each built to initiate a specific type of vulnerability. Once you’ve downloaded the desired data, run it through your pipeline, and upload it along with the outputs to the “Pentest” environment.',
  readMoreBtnLabel: 'Read more in our documentation',
  downloadCSVBtnLabel: 'Download as CSV',
  promptsText: 'Prompts',
  inText: 'IN',
  categoriesText: 'categories',
  inputs: {
    link: {
      label: 'Data Source Link'
    },
    description: {
      label: 'Application Description (required)',
      placeholder: 'Write your application description'
    },
    guideline: {
      name: 'Guidelines',
      label: 'Guidelines (required)',
      placeholder:
        'Write me a set of complicated questions that are focused on the subjects of drift and token classification.'
    },
    dataContext: {
      label: 'Data Context',
      options: {
        link: 'Add Link',
        file: 'Add File'
      }
    }
  },
  viewSamples: {
    numberOfSamples: (samplesNumber: number) => (samplesNumber ? `${samplesNumber} Generated` : ''),
    exportCsv: 'Export To CSV',
    generateMore: 'Generate More',
    downloadedFileName: 'generated.csv'
  }
};
