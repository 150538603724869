import { styled } from '@mui/material';
import { Close } from '@mui/icons-material';

import { StyledButton, StyledContainer, StyledText } from '@/components/lib';

export const StyledPromptsContainer = styled(StyledContainer)<{ is_prompts_loading: string }>(
  ({ is_prompts_loading }) => ({
    padding: 0,
    paddingTop: '8px',
    height: '420px',
    overflow: 'auto',
    borderRadius: 0,
    gap: '8px',
    width: is_prompts_loading === 'true' ? '765px' : '100%',

    ':after': {
      content: '""',
      position: 'absolute',
      left: '0px',
      right: '0px',
      height: '10%',
      bottom: '110px',
      background:
        'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.0086472) 6.67%, rgba(255, 255, 255, 0.03551) 13.33%, rgba(255, 255, 255, 0.0816599) 20%, rgba(255, 255, 255, 0.147411) 26.67%, rgba(255, 255, 255, 0.231775) 33.33%, rgba(255, 255, 255, 0.331884) 40%, rgba(255, 255, 255, 0.442691) 46.67%, rgba(255, 255, 255, 0.557309) 53.33%, rgba(255, 255, 255, 0.668116) 60%, rgba(255, 255, 255, 0.768225) 66.67%, rgba(255, 255, 255, 0.852589) 73.33%, rgba(255, 255, 255, 0.91834) 80%, rgba(255, 255, 255, 0.96449) 86.67%, rgba(255, 255, 255, 0.991353) 93.33%, #FFFFFF 100%)'
    }
  })
);

export const StyledPromptsFooter = styled(StyledContainer)({
  alignItems: 'center',
  flexDirection: 'row',
  padding: 0,
  marginTop: '16px'
});

export const StyledPromptsListItem = styled(StyledContainer)(({ theme }) => ({
  alignItems: 'center',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
  background: theme.palette.grey[100],
  height: '56px',
  borderRadius: '8px',
  paddingLeft: '24px',
  paddingRight: '19px'
}));

export const StyledPromptsFooterTextContainer = styled(StyledContainer)({
  alignItems: 'flex-end',
  flexDirection: 'row',
  gap: '4px'
});

export const StyledPromptsListItemRightContainer = styled(StyledContainer)({
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  gap: '16px',
  width: '229px',
  marginLeft: 'auto',
  padding: 0
});

export const StyledDownloadButton = styled(StyledButton)({
  marginLeft: 'auto',
  height: '42px',
  width: '242px'
});

export const StyledFooterText = styled(StyledText)(({ theme }) => ({
  lineHeight: '20px',
  color: theme.palette.grey[300]
}));

export const StyledInText = styled(StyledText)(({ theme }) => ({
  lineHeight: '20px',
  color: theme.palette.grey[500],
  margin: '0 4px'
}));

export const StyledInputText = styled(StyledText)(({ theme }) => ({
  color: theme.palette.grey[600],
  maxWidth: '490px',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
  display: '-webkit-box',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}));

export const StyledCategoryText = styled(StyledText)(({ theme }) => ({
  color: theme.palette.common.white,
  maxWidth: '190px',
  padding: '5px 8px',
  background: theme.palette.grey[400],
  borderRadius: '40px'
}));

export const StyledCloseIcon = styled(Close)(({ theme }) => ({
  color: theme.palette.grey[400],
  cursor: 'pointer'
}));
