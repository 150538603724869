import React, { useEffect, useState } from 'react';

import { ClearRounded } from '@mui/icons-material';

import { StyledDialogBase, StyledText } from '@/components/lib';
import { ViewSamples } from './ViewSamples/ViewSamples';
import { GenerateSamples } from './GenerateSamples/GenerateSamples';

import { SamplesGenerationHeaderContainer } from '../SamplesGeneration.styes';

import { getStorageItem, removeStorageItem, setStorageItem, storageKeys } from '@/helpers/utils/localStorage';
import {
  destroyAllApiV1GoldensetGeneratorTaskTaskIdDestroyPost,
  createQuestionGenTaskForAppApiV1ApplicationsAppIdGoldensetGeneratorTaskPost
} from '@/helpers/services/api';

import { constants } from '../samplesGeneration.constants';

interface GenerateSamplesDialogProps {
  open: boolean;
  appId: number;
  closeDialog: () => void;
}

export const SamplesGenerationDialog = (props: GenerateSamplesDialogProps) => {
  const { open, appId, closeDialog } = props;

  const [taskId, setTaskId] = useState('');
  const [isViewSamples, setIsViewSamples] = useState(false);
  const [payload, setPayload] = useState({ description: '', guidelines: '' });

  const handleChangeView = () => setIsViewSamples(!isViewSamples);

  const handleClose = () => {
    destroyAllApiV1GoldensetGeneratorTaskTaskIdDestroyPost(taskId);
    removeStorageItem(storageKeys.generationTask);
    closeDialog();

    setTimeout(() => {
      setTaskId('');
      setIsViewSamples(false);
      setPayload({ description: '', guidelines: '' });
    }, 300);
  };

  useEffect(() => {
    const currentGenerationTask = getStorageItem(storageKeys.generationTask);

    if (currentGenerationTask) {
      handleClose();
    }
  }, []);

  useEffect(() => {
    if (open) {
      createQuestionGenTaskForAppApiV1ApplicationsAppIdGoldensetGeneratorTaskPost(appId).then(res => {
        setTaskId(`${res?.id}`);
        setStorageItem(storageKeys.generationTask, res?.id);
      });
    }
  }, [open]);

  return (
    <StyledDialogBase open={open} maxWidth="sm">
      <SamplesGenerationHeaderContainer>
        <StyledText text={constants.generateEvaluationData} type="h1" />
        <ClearRounded onClick={handleClose} color="info" />
      </SamplesGenerationHeaderContainer>
      {isViewSamples ? (
        <ViewSamples taskId={taskId} description={payload.description} guidelines={payload.guidelines} />
      ) : (
        <GenerateSamples
          taskId={taskId}
          payload={payload}
          setPayload={setPayload}
          handleChangeView={handleChangeView}
        />
      )}
    </StyledDialogBase>
  );
};
