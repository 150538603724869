import React from 'react';

import { styled, PopoverProps, Popover, Box, Chip } from '@mui/material';

import { Container } from '../../Container/Container';
import { Text } from '../../Text/Text';
import { Input } from '../../Input/Input';
import { theme } from '../../../theme';

const CheckboxDropdownContainer = styled(Container)(() => ({
  gap: '8px',
  padding: 0,
  maxWidth: '500px'
}));

const StyledLabelText = styled(Text)(({ theme }) => ({
  color: theme.palette.grey[400]
}));

const StyledChip = styled(Chip, { shouldForwardProp: prop => prop !== 'chips' })<{ chips?: number }>(({ chips }) => ({
  height: '22px',
  marginRight: '4px',
  maxWidth: chips ? (Number(chips) < 3 ? `${100 / chips}%` : '30%') : 'auto',

  '.MuiTypography-subtitle2': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: theme.palette.common.white
  }
}));

const StyledSearchInput = styled(Input)({
  margin: '6px 4px 8px',
  width: 'calc(100% - 12px)'
});

const StyledCheckboxContainer = styled(Container)(({ theme }) => ({
  height: 'auto',
  overflow: 'auto',
  maxHeight: '230px',
  padding: '0 4px 8px',

  '&::-webkit-scrollbar': {
    width: '7px'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.grey[300],
    borderRadius: '8px'
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: theme.palette.grey[500]
  }
}));

const StyledEmptyList = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '254px',
  padding: '16px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.grey[500]
}));

const StyledPopover = styled((props: PopoverProps) => (
  <Popover
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    PaperProps={{
      style: {
        width: '466px',
        borderRadius: '8px',
        padding: '12px',
        paddingBottom: 0,
        marginTop: '6px',
        boxShadow: 'none',
        border: `2px solid ${theme.palette.grey[200]}`
      }
    }}
    {...props}
  />
))({});

interface PropertiesDialogPropertyDropdownProps {
  disabled?: boolean;
}

const CheckboxDropdownDropdown = styled(Box)<PropertiesDialogPropertyDropdownProps>(({ theme, disabled }) => ({
  height: '42px',
  border: '1px solid',
  borderColor: theme.palette.grey[300],
  borderRadius: '5px',
  padding: '10px',
  cursor: disabled ? 'default' : 'pointer',
  opacity: disabled ? 0.5 : 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  ':hover': {
    borderColor: disabled ? theme.palette.grey[300] : theme.palette.common.black
  },

  ' p': {
    color: disabled ? theme.palette.grey[300] : theme.palette.common.white
  }
}));

export {
  CheckboxDropdownContainer,
  StyledPopover,
  CheckboxDropdownDropdown,
  StyledLabelText,
  StyledSearchInput,
  StyledCheckboxContainer,
  StyledEmptyList,
  StyledChip
};
