import React, { ReactNode } from 'react';

import { AccordionDetails, AccordionSummary, Accordion as MuiAccordion, Stack, useTheme } from '@mui/material';
import { ExpandMoreOutlined } from '@mui/icons-material';

import { Text } from '../../Text/Text';

import { sxAccordionStyles, sxContainerStyles, sxDividerStyles, sxPaddingStyles } from './Accordion.styles';

export interface AccordionProps {
  title?: string;
  children?: ReactNode | ReactNode[];
  defaultExpanded?: boolean;
}

export const Accordion = ({ title, children, defaultExpanded }: AccordionProps) => {
  const theme = useTheme();

  const grey = theme.palette.grey[300];

  return (
    <Stack sx={sxContainerStyles}>
      <MuiAccordion sx={sxAccordionStyles(grey)} defaultExpanded={defaultExpanded}>
        <AccordionSummary expandIcon={<ExpandMoreOutlined color="info" />} sx={sxPaddingStyles}>
          <Text text={title} overflow="unset" type="bodyBold" />
          <div style={sxDividerStyles(grey)} />
        </AccordionSummary>
        <AccordionDetails sx={sxPaddingStyles}>{children}</AccordionDetails>
      </MuiAccordion>
    </Stack>
  );
};
