import React from 'react';

import { FormControlLabel, Switch, styled } from '@mui/material';

import { paletteOptions } from '../../../theme/palette';

import { Text } from '../../Text/Text';

export interface ToggleProps {
  label?: string;
  disabled?: boolean;
  labelPlacement?: 'start' | 'end';
  state: boolean;
  setState: (arg: boolean) => void;
  withText?: boolean;
}

export const Toggle = (props: ToggleProps) => {
  const { label, disabled, labelPlacement = 'end', state, setState, withText } = props;

  const labelColor = state
    ? paletteOptions.primary?.['main' as keyof typeof paletteOptions.primary]
    : paletteOptions.grey?.[400];

  const StyledFormControlLabel = styled(FormControlLabel)({
    marginRight: 0,

    '& .MuiFormControlLabel-label': {
      marginRight: '9px',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '17px',
      transform: 'translateY(-1px)',

      '&.Mui-disabled': {
        opacity: 0.3
      }
    }
  });

  const StyledSwitch = styled(Switch)<{ with_text?: string }>(({ theme, with_text }) => ({
    width: 54,
    height: 26,
    padding: 0,
    marginRight: 12,
    display: 'flex',

    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 26
      },

      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(16.5px)'
      }
    },

    '& .MuiSwitch-switchBase': {
      padding: 2,

      '&.Mui-checked': {
        transform: 'translateX(28px)',
        color: theme.palette.common.white,

        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.primary.main
        }
      }
    },

    '& .MuiSwitch-thumb': {
      margin: '0',
      backgroundColor: '#ffffff',
      boxShadow: 'none',
      width: 22,
      height: 22,
      borderRadius: 22 / 2,
      transition: theme.transitions.create(['width'], {
        duration: 200
      })
    },

    '& .MuiSwitch-track': {
      opacity: 1,
      borderRadius: '16px',
      backgroundColor: theme.palette.grey['300'],
      boxSizing: 'border-box',

      '&:after, &:before': {
        top: '5px',
        fontSize: 10,
        color: 'white',
        fontWeight: 700,
        lineHeight: '10px',
        position: 'absolute'
      },

      '&:after': {
        left: '6px',
        fontSize: '14px',
        marginTop: '2px',
        content: with_text === 'true' ? "'on'" : "''"
      },

      '&:before': {
        right: '5px',
        fontSize: '14px',
        marginTop: '2px',
        content: with_text === 'true' ? "'off'" : "''"
      }
    }
  }));

  return (
    <StyledFormControlLabel
      control={
        <StyledSwitch
          onChange={() => setState(!state)}
          checked={state}
          with_text={withText ? 'true' : 'false'}
          data-testid="Toggle"
        />
      }
      label={label && <Text text={label} color={labelColor} type="h3" />}
      labelPlacement={labelPlacement}
      disabled={disabled}
    />
  );
};
