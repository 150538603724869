import React from 'react';

import {
  List,
  ListItem,
  ListItemButton,
  Popover,
  PopoverProps,
  Stack,
  TableContainer,
  alpha,
  styled
} from '@mui/material';

import { StyledButton, StyledContainer, StyledText } from '@/components/lib';

export const SamplesGenerationHeaderContainer = styled(StyledContainer)(() => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px',

  '.MuiSvgIcon-root': {
    cursor: 'pointer'
  }
}));

export const SamplesGenerationDialogContentContainer = styled(StyledContainer)(() => ({
  width: '558px',
  gap: '16px',
  padding: '0 16px 16px'
}));

export const DialogFlexRowGapContainer = styled(StyledContainer)({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0'
});

export const DialogButtonsContainer = styled(StyledContainer)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: 0,

  '.MuiButtonBase-root': {
    borderRadius: '8px',
    padding: '8px 0',
    width: '50%',
    border: `2px solid ${theme.palette.grey[200]}`,
    color: theme.palette.grey[300]
  },

  '.MuiSvgIcon-root': {
    cursor: 'pointer'
  }
}));

export const ViewSamplesContainer = styled(StyledContainer)({
  padding: '16px',
  width: '868px',
  gap: '36px'
});

export const ViewSamplesTableContainer = styled(StyledContainer)(({ theme }) => ({
  borderRadius: 0,
  padding: 0,
  boxShadow: 'none',
  height: '300px',
  overflow: 'auto',

  '.MuiSvgIcon-root': {
    fill: theme.palette.grey[300],
    cursor: 'pointer'
  }
})) as typeof TableContainer;

export const ViewSamplesTableRow = styled(Stack)(({ theme }) => ({
  width: '100%',
  flexDirection: 'row',
  backgroundColor: theme.palette.grey[100],
  borderRadius: '16px',
  padding: '16px',
  justifyContent: 'space-between',
  gap: '24px',
  alignItems: 'center'
}));

export const ViewSamplesButtonsContainer = styled(StyledContainer)({
  flexDirection: 'row',
  justifyContent: 'right',
  width: '60%',
  gap: '24px',
  alignItems: 'center',

  '.MuiButtonBase-root': {
    width: '160px'
  }
});

export const ViewSamplesLoaderContainer = styled(StyledContainer)({
  height: '80px',
  justifyContent: 'center',
  margin: 'auto'
});

export const SamplesGenerationNavButton = styled(StyledButton)({
  height: '40px',
  minWidth: '12px'
});

export const GenerateSamplesSubmitBtn = styled(StyledButton)({
  width: '15ox',
  margin: '16px auto 8px'
});

export const DataContextItemContainer = styled(StyledContainer)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: theme.palette.grey[100],
  padding: '8px 16px',

  '.MuiSvgIcon-root': {
    cursor: 'pointer'
  }
}));

export const DataContextItemText = styled(StyledText)({
  margin: '0 auto 0 8px'
});

export const SamplesGenerationPlaceholder = styled(StyledContainer)({
  marginTop: 'auto'
});

export const StyledPopover = styled((props: PopoverProps) => (
  <Popover
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'left'
    }}
    transformOrigin={{
      vertical: 'bottom',
      horizontal: 'left'
    }}
    slotProps={{
      paper: {
        style: { width: 'auto', borderRadius: '8px', padding: '8px' }
      }
    }}
    {...props}
  />
))({});

export const StyledListItem = styled(ListItem)({ padding: 0 });

export const StyledList = styled(List)({ padding: 0 });

export const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  padding: '8px',

  '.MuiTypography-root': {
    fontWeight: 700,
    fontSize: '14px',
    marginRight: 'auto',
    whiteSpace: 'nowrap',
    color: theme.palette.grey[400]
  },

  ':hover': {
    borderRadius: '8px',
    background: alpha(theme.palette.primary.main, 0.07)
  },

  ':hover p': {
    color: theme.palette.primary.main
  }
}));

export const SamplesGenerationContentContainer = styled(Stack)({
  padding: '8px 16px 24px',
  gap: '8px',
  maxWidth: '797px'
});

export const StyledLinkContainer = styled(Stack)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'flex-end',
  flexDirection: 'row',
  cursor: 'pointer',
  fontWeight: 700,
  color: theme.palette.primary.main,
  gap: '8px',
  marginLeft: '4px'
}));

export const StyledDescriptionText = styled(StyledText)(({ theme }) => ({
  color: theme.palette.grey[400]
}));
