import React from 'react';

import { DialogProps, Dialog, DialogContent } from '@mui/material';

import { sxDialogBaseContentStyles, sxDialogBaseStyles } from './Dialog.styles';

export const DialogBase = (props: DialogProps) => {
  const { open, children, sx, ...otherProps } = props;

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Escape' && otherProps?.onClose) {
      otherProps?.onClose(event, 'escapeKeyDown');
    }
  };

  return (
    <Dialog open={open} onKeyDown={handleKeyDown} sx={{ ...sxDialogBaseStyles, ...sx }} {...otherProps}>
      <DialogContent sx={sxDialogBaseContentStyles}>{children}</DialogContent>
    </Dialog>
  );
};
