import { TypographyOptions } from '@mui/material/styles/createTypography';

import { paletteOptions } from './palette';

export const typographyOptions: TypographyOptions = {
  fontFamily: 'Manrope, sans-serif',
  h1: {
    fontSize: '32px',
    fontWeight: 500,
    lineHeight: '40px',
    color: paletteOptions.grey?.[500],

    '@media (max-width:1536px)': {
      fontSize: '24px',
      lineHeight: '28px'
    },

    contrasts: {
      low: paletteOptions.grey?.[500],
      medium: paletteOptions.grey?.[500],
      high: paletteOptions.grey?.[500]
    }
  },
  h2: {
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '32px',
    color: paletteOptions.grey?.[500],

    '@media (max-width:1536px)': { fontSize: '20px' },

    contrasts: {
      low: paletteOptions.grey?.[400],
      medium: paletteOptions.grey?.[400],
      high: paletteOptions.grey?.[600]
    }
  },
  h3: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '24px',
    color: paletteOptions.grey?.[600],

    '@media (max-width:1536px)': { fontSize: '16px' },

    contrasts: {
      low: paletteOptions.grey?.[400],
      medium: paletteOptions.grey?.[500],
      high: paletteOptions.grey?.[600]
    }
  },
  h5: {
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '20px',
    color: paletteOptions.grey?.[500],

    '@media (max-width:1536px)': { fontSize: '12px' },

    contrasts: {
      low: paletteOptions.grey?.[400],
      medium: paletteOptions.grey?.[500],
      high: paletteOptions.grey?.[600]
    }
  },
  body1: {
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: '21px',
    color: paletteOptions.grey?.[500],

    '@media (max-width:1536px)': { fontSize: '14px' },

    contrasts: {
      low: paletteOptions.grey?.[400],
      medium: paletteOptions.grey?.[500],
      high: paletteOptions.grey?.[600]
    }
  },
  body2: {
    fontSize: '15px',
    fontWeight: 700,
    lineHeight: '21px',
    color: paletteOptions.grey?.[400],

    '@media (max-width:1536px)': { fontSize: '14px' },

    contrasts: {
      low: paletteOptions.grey?.[400],
      medium: paletteOptions.grey?.[500],
      high: paletteOptions.grey?.[600]
    }
  },
  subtitle1: {
    fontSize: '13px',
    fontWeight: 500,
    lineHeight: '15.4px',
    color: paletteOptions.grey?.[400],

    '@media (max-width:1536px)': { fontSize: '11px' },

    contrasts: {
      low: paletteOptions.grey?.[300],
      medium: paletteOptions.grey?.[500],
      high: paletteOptions.grey?.[600]
    }
  },
  subtitle2: {
    fontSize: '13px',
    fontWeight: 700,
    lineHeight: '15.4px',
    color: paletteOptions.grey?.[400],

    '@media (max-width:1536px)': { fontSize: '11px' },

    contrasts: {
      low: paletteOptions.grey?.[300],
      medium: paletteOptions.grey?.[500],
      high: paletteOptions.grey?.[600]
    }
  },
  button: {
    fontSize: '15px',
    fontWeight: 700,
    lineHeight: '21px',
    textTransform: 'none',

    '@media (max-width:1536px)': { fontSize: '14px' },

    contrasts: {
      low: paletteOptions.grey?.[400],
      medium: paletteOptions.grey?.[500],
      high: paletteOptions.grey?.[600]
    }
  }
};
