export const downloadFile = async (filePath: string, downloadedName: string) => {
  const response = await fetch(filePath, { method: 'GET' });

  if (response.status === 200) {
    const contentDisposition = response.headers.get('Content-Disposition');
    const filename = contentDisposition ? contentDisposition.split('filename=')[1] : downloadedName;
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');

    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }
};

export const downloadServerFile = async (res: unknown, downloadedName: string) => {
  const blob = new Blob([res as BlobPart], { type: 'text/csv' });
  const downloadUrl = URL.createObjectURL(blob);
  const anchor = document.createElement('a');

  anchor.href = downloadUrl;
  anchor.download = downloadedName;
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
  URL.revokeObjectURL(downloadUrl);
};
