import React, { useEffect, useState } from 'react';

import { StyledLoader, StyledText } from '@/components/lib';

import { SamplesGenerationContentContainer } from '../../SamplesGeneration.styes';
import {
  StyledPromptsContainer,
  StyledDownloadButton,
  StyledPromptsFooter,
  StyledPromptsFooterTextContainer,
  StyledFooterText,
  StyledPromptsListItem,
  StyledInputText,
  StyledCategoryText,
  StyledPromptsListItemRightContainer,
  StyledInText
} from './PromptsList.styes';

import { downloadServerFile } from '@/helpers/utils/downloadFile';
import {
  PentestData,
  getPentestPromptsApiV1PentestPromptsGet,
  getPentestPromptsPerCategoryApiV1PentestInputsPerCategoryGet
} from '@/helpers/services/api';

import { constants } from '../../samplesGeneration.constants';

interface PromptsListProps {
  selectedCategories: string[];
  appId: number;
}

const { downloadCSVBtnLabel, promptsText, inText, categoriesText } = constants;

export const PromptsList = ({ selectedCategories, appId }: PromptsListProps) => {
  const [selectedPromptsList, setSelectedPromptsList] = useState<PentestData[]>([]);
  const [selectedPromptsCount, setSelectedPromptsCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isPromptsLoading, setIsPromptsLoading] = useState(false);

  const handleDownload = async () => {
    setIsLoading(true);

    const rightVulnTypeList = Array?.isArray(selectedPromptsList)
      ? selectedPromptsList.reduce((acc, el) => {
          if (acc.includes(el.vuln_type)) {
            return acc;
          }

          return [...acc, el.vuln_type];
        }, [] as string[])
      : [];

    await getPentestPromptsApiV1PentestPromptsGet({
      app_id: appId,
      probes: rightVulnTypeList
    })
      .then(res => downloadServerFile(res, 'Prompts'))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    setIsPromptsLoading(true);
    getPentestPromptsPerCategoryApiV1PentestInputsPerCategoryGet({
      app_id: appId,
      categories: selectedCategories
    })
      .then(res => {
        setSelectedPromptsList(res.pentest_data);
        setSelectedPromptsCount(res.count);
      })
      .finally(() => setIsPromptsLoading(false));
  }, []);

  return (
    <SamplesGenerationContentContainer>
      <StyledPromptsContainer is_prompts_loading={isPromptsLoading ? 'true' : 'false'}>
        {isPromptsLoading ? (
          <StyledLoader />
        ) : (
          selectedPromptsList.map(el => (
            <StyledPromptsListItem key={el.input}>
              <StyledInputText text={el.input} type="body" />
              <StyledPromptsListItemRightContainer>
                <StyledCategoryText text={el.category} type="tiny" />
              </StyledPromptsListItemRightContainer>
            </StyledPromptsListItem>
          ))
        )}
      </StyledPromptsContainer>
      <StyledPromptsFooter>
        <StyledPromptsFooterTextContainer>
          <StyledText text={selectedPromptsCount} type="h3" />
          <StyledFooterText text={promptsText} type="small" />
          <StyledInText text={inText} type="small" />
          <StyledText text={selectedCategories.length} type="h3" />
          <StyledFooterText text={categoriesText} type="small" />
        </StyledPromptsFooterTextContainer>
        <StyledDownloadButton
          loading={isLoading}
          disabled={isLoading}
          onClick={handleDownload}
          label={downloadCSVBtnLabel}
        />
      </StyledPromptsFooter>
    </SamplesGenerationContentContainer>
  );
};
