import React from 'react';

import { EditOutlined } from '@mui/icons-material';
import { useTheme, Box, Stack } from '@mui/material';

import { Text } from '../../Text/Text';
import { ToolTip } from '../ToolTip/ToolTip';

import { sxContainerStyles, sxIconStyles, sxTextContainerStyles, sxWrapperStyles } from './PercentageContainer.styles';

interface PercentageContainerProps {
  percentage: number;
  amount?: number;
  maxWidth?: string;
  title?: string;
  border?: boolean;
  chosen?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  editButton?: {
    label: string;
    onClick: () => void;
  };
}

export const PercentageContainer = (props: PercentageContainerProps) => {
  const { title, amount, percentage, chosen, border, maxWidth, disabled, editButton, onClick } = props;

  const theme = useTheme();

  const coverageText = `${typeof amount === 'number' ? `${amount} Interactions` : ''} (${Math.round(Number(percentage) * 100)}%)`;
  const textColor = chosen ? theme.palette.primary.main : theme.palette.grey[500];
  const titleTextColor = chosen ? theme.palette.primary.main : theme.palette.grey[600];
  const bgColor = chosen ? theme.palette.primary.main : theme.palette.grey[200];
  const gradientPercentage = `${percentage * 100}%`;
  const gradientColorStops = `${theme.palette.grey[200]} ${gradientPercentage}, ${theme.palette.grey[100]} ${gradientPercentage}`;
  const tooltipContent = Number(title?.length) > 20 ? title : '';

  const editButtonOnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e?.stopPropagation();
    editButton && editButton?.onClick();
  };

  return (
    <Box sx={sxContainerStyles(bgColor, border)} data-testid={`PercentageContainer${title}`}>
      <ToolTip text={tooltipContent}>
        <Box
          data-testid="percentage-container"
          onClick={onClick && !disabled ? onClick : undefined}
          sx={sxWrapperStyles(gradientColorStops, disabled || false, border || false, onClick ? true : false, maxWidth)}
        >
          <Text text={title} color={titleTextColor} type="bodyBold" fontSize={15} whiteSpace="nowrap" />
          <Stack direction="row" justifyContent="space-between">
            <Text text={coverageText} color={textColor} type="tinyBold" />
            {editButton?.label && (
              <Stack className="edit-container" sx={sxTextContainerStyles(theme)}>
                <EditOutlined sx={sxIconStyles(theme)} />
                <Text
                  type="tinyBold"
                  text={editButton.label}
                  onClick={editButtonOnClick}
                  color={theme.palette.grey[600]}
                />
              </Stack>
            )}
          </Stack>
        </Box>
      </ToolTip>
    </Box>
  );
};
