import React from 'react';

import { Stack, StackProps, useTheme } from '@mui/material';

import { UpdateRounded } from '@mui/icons-material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';

import { StyledText } from '../../..';

import { sxStyles } from './Thumbs.styles';

export type ThumbColor = 'green' | 'red' | 'grey';

interface ThumbsProps extends StackProps {
  outlined?: boolean;
  isUnknown?: boolean;
  label?: string;
  thumbDirection?: 'up' | 'down';
  color?: ThumbColor;
}

export const Thumbs = (props: ThumbsProps) => {
  const { outlined = false, label, thumbDirection = 'up', color = 'grey', isUnknown, ...otherProps } = props;

  const { palette } = useTheme();

  const getThumbColor = (color: ThumbColor) => {
    switch (color) {
      case 'green':
        return palette.success.main;
      case 'red':
        return palette.error.main;
      case 'grey':
        return palette.grey[400];
      default:
        return palette.grey[400];
    }
  };

  return (
    <Stack gap="5px" alignItems="center" justifyContent="center" {...otherProps}>
      {isUnknown ? (
        <UpdateRounded sx={sxStyles(getThumbColor, color)} />
      ) : thumbDirection === 'up' ? (
        outlined ? (
          <ThumbUpOutlinedIcon sx={sxStyles(getThumbColor, color)} />
        ) : (
          <ThumbUpIcon sx={sxStyles(getThumbColor, color)} />
        )
      ) : outlined ? (
        <ThumbDownOutlinedIcon sx={sxStyles(getThumbColor, color)} />
      ) : (
        <ThumbDownIcon sx={sxStyles(getThumbColor, color)} />
      )}
      {label && <StyledText text={label} />}
    </Stack>
  );
};
