import { Theme } from '@mui/material';

export const sharedStyles = {
  whiteSpace: 'pre-wrap'
};

export const sxStyles = (theme: Theme, isOnclick: boolean, highlightedTextBold?: boolean) => ({
  fontWeight: highlightedTextBold ? 700 : 500,
  color: theme.palette.primary.main,
  cursor: isOnclick ? 'pointer' : 'text',
  transition: 'opacity 0.3s',
  ...sharedStyles,

  '&:hover': {
    opacity: isOnclick ? 0.7 : 1
  },

  '&:active': {
    opacity: isOnclick ? 0.5 : 1
  }
});
