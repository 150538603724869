import React from 'react';
import { Divider, PaletteOptions, Stack, Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';
import { Text } from '../Text/Text';
import { DataInterface } from './DifferenceBar';

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey[400],
    boxShadow: '2px 2px 30px -10px #29354340',
    fontSize: 11
  }
}));

export const StyledDifferenceBarContainer = styled(Stack)(({ theme }) => ({
  padding: '8px 12px',
  borderRadius: '8px',
  border: `2px solid ${theme.palette.grey[300]}`,
  marginBottom: '16px',
  height: '90px',
  '@media (min-width:1440px)': {
    padding: '6px 12px'
  }
}));

export const StyledTooltipContent = styled(Stack)({
  flexDirection: 'column',
  justifyContent: 'flex-start',
  marginTop: 0
});

export const StyledNoDataText = styled(Text)({
  margin: '7px auto'
});

export const StyledTooltipVersionContent = styled(Stack)({
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'row'
});

export const StyledSliderContainer = styled(Stack)(({ theme }) => ({
  overflow: 'auto',
  gap: '4px',
  display: 'grid',

  '&::-webkit-scrollbar': {
    height: '6px'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.grey[300],
    borderRadius: '8px'
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: theme.palette.grey[500]
  }
}));

export const StyledTooltipScoreContent = styled(Stack)({
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
  margin: '10px 0'
});

export const StyledDivider = styled(Divider)(({ theme }) => ({
  height: '12px',
  width: '1px',
  color: theme.palette.grey[400],
  margin: '0 6px'
}));

export const StyledScoreDivider = styled(Divider)(({ theme }) => ({
  height: '2px',
  width: '30%',
  color: theme.palette.grey[300]
}));

export const StyledHorizontalDivider = styled(Divider)(({ theme }) => ({
  height: '2px',
  width: '100%',
  color: theme.palette.grey[300],
  margin: '6px 0 8px'
}));

export const sxStyles = { marginRight: 'auto' };

export const sxAnnotationContainerStyles = (currentPage: number, index: number, paletteOptions: PaletteOptions) => ({
  height: '32px',
  width: '32px !important',
  margin: '0 2px 4px',
  cursor: 'pointer',
  borderRadius: '0px',
  transition: 'opacity 0.3s',
  position: 'relative',
  overflow: 'hidden',

  '&:hover': { opacity: 0.8 },
  ...(currentPage === index && {
    border: `3px solid ${paletteOptions.grey?.[500 as keyof typeof paletteOptions.grey]}`,
    borderRadius: '2px'
  })
});

export const sxFirstTriangleStyles = (
  currentPage: number,
  index: number,
  item: DataInterface,
  getCurBorder: (itemType?: string) => string | undefined
) => ({
  content: '""',
  position: 'absolute',
  left: 0,
  top: 0,
  height: '3px',
  width: currentPage === index ? '87%' : '80%',
  borderBottom: getCurBorder(item.firstItemAnnotationType) || '',
  zIndex: 1
});

export const sxSecondTriangleStyles = (
  currentPage: number,
  index: number,
  item: DataInterface,
  getCurBorder: (itemType?: string) => string | undefined
) => ({
  content: '""',
  position: 'absolute',
  left: currentPage === index ? '-9px' : '-10px',
  top: currentPage === index ? '11px' : '13px',
  height: '3px',
  width: currentPage === index ? '80%' : '72%',
  borderBottom: getCurBorder(item.firstItemAnnotationType) || '',
  zIndex: 1,
  transform: 'rotate(90deg)'
});

export const sxWhiteContainerStyles = (
  currentPage: number,
  index: number,
  item: DataInterface,
  getCurBorder: (itemType?: string) => string | undefined,
  getCurAnnotatedBackground: (
    itemType: string
  ) => 'rgba(18, 175, 105, 0.5)' | 'rgba(238, 57, 93, 0.5)' | '#E2E2EE' | '#ffffff'
) => ({
  position: 'absolute',
  height: '32px',
  width: '45.25px !important',
  background: getCurAnnotatedBackground(item.firstItemAnnotationType || ''),
  borderTop: getCurBorder(item.firstItemAnnotationType) || '',
  transform: 'rotate(135deg)',
  right: currentPage === index ? '5px' : '7px',
  top: '-11px'
});

export const sxSecondContainerStyles = (
  currentPage: number,
  index: number,
  item: DataInterface,
  getCurBorder: (itemType?: string) => string | undefined,
  getCurAnnotatedBackground: (
    itemType: string
  ) => 'rgba(18, 175, 105, 0.5)' | 'rgba(238, 57, 93, 0.5)' | '#E2E2EE' | '#ffffff'
) => ({
  position: 'absolute',
  height: '32px',
  width: '45.25px !important',
  background: getCurAnnotatedBackground(item.secondItemAnnotationType || ''),
  borderTop: getCurBorder(item.secondItemAnnotationType) || '',
  transform: 'rotate(315deg)',
  left: currentPage === index ? '1px' : '5px',
  top: '11px'
});

export const sxFirstEstimatedTriangleStyles = (
  currentPage: number,
  index: number,
  item: DataInterface,
  getCurBorder: (itemType?: string) => string | undefined
) => ({
  content: '""',
  position: 'absolute',
  right: 0,
  bottom: 0,
  height: '3px',
  width: currentPage === index ? '76%' : '86%',
  borderBottom: getCurBorder(item.secondItemAnnotationType) || '',
  zIndex: 1
});

export const sxSecondEstimatedTriangleStyles = (
  currentPage: number,
  index: number,
  item: DataInterface,
  getCurBorder: (itemType?: string) => string | undefined
) => ({
  content: '""',
  position: 'absolute',
  right: currentPage === index ? '-7px' : '-11px',
  bottom: currentPage === index ? '10px' : '14px',
  height: '3px',
  width: currentPage === index ? '65%' : '78%',
  borderBottom: getCurBorder(item.secondItemAnnotationType) || '',
  zIndex: 1,
  transform: 'rotate(90deg)'
});

export const sxMainTrianglesStyles = (
  currentPage: number,
  index: number,
  item: DataInterface,
  isTwoScore: boolean,
  minScore: number,
  paletteOptions: PaletteOptions,
  getCurColorValue: (score?: string | number) => number
) => ({
  height: '32px',
  width: '32px !important',
  margin: '0 2px 4px',
  cursor: 'pointer',
  borderRadius: '0px',
  transition: 'opacity 0.3s',
  position: 'relative',
  background: isTwoScore
    ? `linear-gradient(135deg, rgba(145, 89, 247, ${getCurColorValue(item.score)}) 50%, #ffffff 50%, #ffffff 52%, rgba(145, 89, 247, ${getCurColorValue(item.secondItemScore)}) 50%);`
    : `rgba(145, 89, 247, ${(typeof item.score === 'number' ? item.score : 0) < 0.1 ? 1 : (minScore || 0.1) / (typeof item.score === 'number' ? item.score : 0)})`,

  '&:hover': { opacity: 0.8 },
  ...(currentPage === index && {
    border: `3px solid ${paletteOptions.grey?.[500 as keyof typeof paletteOptions.grey]}`,
    borderRadius: '2px'
  })
});
