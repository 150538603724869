import React from 'react';

import { MenuItem } from '@mui/material';

import { Text } from '../Text/Text';
import { highlightText } from './Dropdown.helpers';

import { paletteOptions } from '../../theme/palette';
import {
  sxDropdownItemFistTestStyles,
  sxDropdownItemFourthTestStyles,
  sxDropdownItemSecondTestStyles,
  sxDropdownItemTestStyles,
  sxDropdownItemThirdTestStyles
} from './Dropdown.styles';

interface DropdownItemProps {
  state: {
    name: string;
    amount?: string;
  }[];
  inputValue: string;
  handleChange: (name: string) => void;
  selectedValue: string | undefined;
  isEnvSelect?: boolean;
  isNoEmptyText?: boolean;
}

const NO_RESULTS_STRING = 'No results, try a different combination';

export const DropdownItem = (props: DropdownItemProps) => {
  const { inputValue, state, handleChange, selectedValue, isEnvSelect, isNoEmptyText } = props;

  return (
    <>
      {state.length > 0 ? (
        state.map(({ name, amount }, i) => (
          <MenuItem
            key={i + name}
            value={name}
            onClick={handleChange.bind(null, name)}
            selected={selectedValue === name}
          >
            {isEnvSelect ? (
              <>
                <Text
                  text={inputValue ? highlightText(name.split('|')[0], inputValue) : name.split('|')[0]}
                  type="button"
                  sx={sxDropdownItemFistTestStyles(paletteOptions)}
                />
                <Text
                  text={inputValue ? highlightText(`| ${name.split('|')[1]}`, inputValue) : `| ${name.split('|')[1]}`}
                  type="button"
                  sx={sxDropdownItemSecondTestStyles(paletteOptions)}
                />
              </>
            ) : (
              <>
                <Text
                  text={inputValue ? highlightText(name, inputValue) : name}
                  type="button"
                  sx={sxDropdownItemTestStyles(paletteOptions, name, selectedValue)}
                />
                {amount && <Text text={amount} type="tiny" sx={sxDropdownItemThirdTestStyles(paletteOptions)} />}
              </>
            )}
          </MenuItem>
        ))
      ) : isNoEmptyText ? null : (
        <Text type="small" text={NO_RESULTS_STRING} sx={sxDropdownItemFourthTestStyles} />
      )}
    </>
  );
};
