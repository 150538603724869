import mixpanel from 'mixpanel-browser';

import { getStorageItem, storageKeys } from '@/helpers/utils/localStorage';
import { getSessionStorageItem, sessionStorageKeys } from '../utils/sessionStorage';

export const constants = {
  src: 'Frontend',
  orgsToIgnore: { dc: 'deepchecks' }
};

export const reportEvent = (eventName: string, eventData?: { [param: string]: string | number | boolean }) => {
  const appProperties = getStorageItem(storageKeys.app);
  const userProperties = getStorageItem(storageKeys.user);
  const mixpanelInit = getSessionStorageItem(sessionStorageKeys.mixpanelInit);

  if (mixpanelInit === true && !userProperties?.email?.includes(constants?.orgsToIgnore?.dc)) {
    mixpanel?.track(eventName, { ...eventData, ...userProperties, ...appProperties, src: constants?.src });
  }
};

export const resetMixpanel = () => {
  const mixpanelInit = getSessionStorageItem(sessionStorageKeys.mixpanelInit);

  if (mixpanelInit === true) {
    mixpanel?.reset();
  }
};

export const events = {
  properties: {
    llm: {
      tabView: 'LLM Properties Tab View',
      propertyDialog: {
        open: 'Open LLM Property Dialog',
        test: 'Test LLM Property',
        save: 'Save LLM Property'
      },
      recalculate: {
        openDialog: 'Recalculate LLM Prop - Open Dialog',
        run: 'Recalculate LLM Prop - Run'
      },
      deleteProperty: 'Delete LLM Property'
    }
  },
  annotation: {
    rerun: 'Recalculate Annotations'
  }
};
