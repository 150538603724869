import React from 'react';

import { styled, alpha, Avatar, Box, Divider, Stack, PopoverProps, Popover } from '@mui/material';

import { StyledContainer, StyledText } from '@/components/lib';

export const UserInfoContainer = styled(Box)(() => ({
  margin: '-8px 4px'
}));

export const UserInfoAvatar = styled(Avatar)(({ theme }) => ({
  width: '36px',
  height: '36px',
  border: `2px solid ${alpha(theme.palette.common.white, 0.2)}`
}));

export const UserInfoAvatarContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  cursor: 'pointer',
  alignItems: 'center',
  gap: '8px',
  margin: '16px 0'
}));

export const UserInfoText = styled(StyledText)(() => ({
  width: '160px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis'
}));

export const CompleteDetailsDialogContainer = styled(StyledContainer)(() => ({
  padding: '16px',
  justifyContent: 'center',
  gap: '36px',

  '.MuiButtonBase-root': {
    width: '200px',
    margin: '0 auto'
  }
}));

export const UserInfoMenuContainer = styled((props: PopoverProps) => (
  <Popover
    slotProps={{ paper: { style: { width: '230px', borderRadius: '8px', padding: '16px', gap: '16px' } } }}
    {...props}
  />
))(({ theme }) => ({
  '.MuiPaper-root': {
    background: theme.palette.grey[200],
    border: `2px solid ${theme.palette.grey[300]}`
  },

  a: { textDecoration: 'none' }
}));

export const UserInfoMenuDivider = styled(Divider)(({ theme }) => ({
  width: '100%',
  margin: '16px 0',
  height: '2px',
  background: theme.palette.grey[300]
}));

export const UserInfoMenuClickableContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: '8px',
  padding: '8px 0',
  height: '45px',
  cursor: 'pointer',

  '.MuiSvgIcon-root': { fill: theme.palette.grey[400] },

  '&:hover': {
    '.MuiTypography-root': { color: theme.palette.primary.main },
    '.MuiSvgIcon-root': { fill: theme.palette.primary.main }
  }
}));

export const UserInfoMenuLogout = styled(StyledText)(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.grey[600]
}));

export const UserInfoMenuHeaderContainer = styled(Stack)({
  gap: '8px',
  flexDirection: 'row',
  alignItems: 'center',

  '.MuiTypography-root': { whiteSpace: 'nowrap', width: '150px' }
});
