import { CircularProgress, Stack, styled } from '@mui/material';
import { KeyboardArrowLeftRounded, KeyboardArrowRightRounded } from '@mui/icons-material';

import { StyledButton, StyledContainer } from '../lib';

export const DesktopContainer = styled(StyledContainer)(() => ({
  gap: 0,
  padding: 0,
  width: '100%',
  height: '100vh',
  borderRadius: 0,
  minHeight: '700px',
  minWidth: '1200px',
  flexDirection: 'row'
}));

export const SidebarContainer = styled(StyledContainer, {
  shouldForwardProp: prop => prop !== 'iconsOnly'
})<{ iconsOnly?: boolean }>(({ iconsOnly }) => ({
  gap: '16px',
  height: '100%',
  borderRadius: 0,
  padding: '16px',
  minHeight: '100vh',
  transition: '0.1s',
  width: iconsOnly ? '80px' : '240px',
  textAlign: iconsOnly ? 'center' : 'left',
  alignItems: iconsOnly ? 'center' : 'left',

  img: { margin: iconsOnly ? '18px auto 30px' : '0 auto' }
}));

export const SidebarCloseIcon = styled(KeyboardArrowLeftRounded)(({ theme }) => ({
  zIndex: 2,
  cursor: 'pointer',
  borderRadius: '50%',
  margin: '15px -28px -62px auto',
  fill: theme.palette.common.white,
  background: theme.palette.grey[500]
}));

export const SidebarOpenIcon = styled(KeyboardArrowRightRounded)(({ theme }) => ({
  zIndex: 2,
  cursor: 'pointer',
  borderRadius: '50%',
  margin: '15px -28px -62px auto',
  fill: theme.palette.common.white,
  background: theme.palette.grey[500]
}));

export const DesktopChildrenContainer = styled(StyledContainer)({
  height: '100%',
  borderRadius: 0,
  overflow: 'auto',
  padding: '0 16px',
  minHeight: '100vh',
  maxWidth: '2400px'
});

export const SidebarButton = styled(StyledButton)({
  gap: '8px',
  height: '40px',
  minWidth: '24px',
  marginTop: 'auto'
});

export const SidebarSeparator = styled(Stack)(({ theme }) => ({
  width: '100%',
  height: '1.5px',
  marginTop: '16px',
  background: theme.palette.grey[300]
}));

export const LayoutLoader = styled(CircularProgress)({
  margin: '30vh auto'
});
