import React, { useState, useRef, useEffect } from 'react';

import dayjs, { Dayjs } from 'dayjs';

import { Box, Popover, Stack } from '@mui/material';
import { KeyboardArrowDownOutlined } from '@mui/icons-material';

import {
  StyledButton,
  StyledResetButton,
  StyledStaticDatePicker,
  StyledButtonsContainer,
  AppFiltersTimeFilterButton
} from './DateTimePicker.styles';

interface DateTimePickerProps {
  timestamp: { start: number | undefined; end: number | undefined };
  resetToDefault?: () => void;
  setTimestamp: (newDates: { start: number; end: number }) => void;
}

const constants = {
  apply: 'Apply',
  all: 'All Time',
  reset: 'Reset To Default'
};

export const DateTimePicker = (props: DateTimePickerProps) => {
  const { timestamp, setTimestamp, resetToDefault } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [tempStartValue, setTempStartValue] = useState<number | undefined>(timestamp?.start);
  const [tempEndValue, setTempEndValue] = useState<number | undefined>(timestamp?.end);

  const selectRef = useRef<HTMLDivElement>(null);
  const openDatePicker = Boolean(anchorEl);

  const openDateTimePicker = () => {
    selectRef?.current && setAnchorEl(selectRef.current);

    if (!tempStartValue && timestamp.start) {
      setTempStartValue(timestamp.start);
    }
    if (!tempEndValue && timestamp.end) {
      setTempEndValue(timestamp.end);
    }
    selectRef?.current && setAnchorEl(selectRef.current);
  };

  const closeDateTimePicker = () => {
    setAnchorEl(null);
    setTempEndValue(timestamp?.end);
    setTempStartValue(timestamp?.start);
  };

  const onApply = () => {
    if (!tempStartValue && !tempEndValue) {
      setAnchorEl(null);
    } else {
      setAnchorEl(null);

      const startDateStartOfDay = dayjs(Number(tempStartValue) * 1000)
        ?.startOf('day')
        ?.unix();
      const endDateEndOfDay = dayjs(Number(tempEndValue) * 1000)
        ?.endOf('day')
        ?.unix();

      // Fallback in case start or end isn't provided
      const startDateEndOfDay = dayjs(Number(tempStartValue) * 1000)
        ?.endOf('day')
        ?.unix();
      const endDateStartOfDay = dayjs(Number(tempEndValue) * 1000)
        ?.endOf('day')
        ?.unix();

      setTimestamp({
        start: tempStartValue ? startDateStartOfDay : endDateStartOfDay,
        end: tempEndValue ? endDateEndOfDay : startDateEndOfDay
      });
    }
  };

  const handleReset = () => {
    if (resetToDefault) {
      resetToDefault();
      setAnchorEl(null);
    }
  };

  useEffect(() => {
    setTempStartValue(timestamp?.start);
    setTempEndValue(timestamp?.end);
  }, [timestamp?.start, timestamp?.end]);

  return (
    <Box ref={selectRef}>
      <AppFiltersTimeFilterButton
        variant="contained"
        onClick={openDateTimePicker}
        data-testid="DateTimePicker"
        endIcon={<KeyboardArrowDownOutlined />}
      >
        {!timestamp?.start || !timestamp?.end
          ? constants.all
          : `${dayjs(timestamp?.start * 1000).format('ll')} - ${dayjs(timestamp?.end * 1000).format('ll')}`}
      </AppFiltersTimeFilterButton>
      <Popover
        anchorEl={anchorEl}
        open={openDatePicker}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        onClose={closeDateTimePicker}
      >
        <Stack direction="row" gap="2px">
          <StyledStaticDatePicker
            value={tempStartValue ? dayjs(tempStartValue * 1000) : null}
            maxDate={tempEndValue ? dayjs(tempEndValue * 1000) : undefined}
            onChange={(newValue: Dayjs | null) => setTempStartValue(newValue ? newValue.unix() : undefined)}
          />
          <StyledStaticDatePicker
            value={tempEndValue ? dayjs(tempEndValue * 1000) : null}
            minDate={tempStartValue ? dayjs(tempStartValue * 1000) : undefined}
            onChange={(newValue: Dayjs | null) => setTempEndValue(newValue ? newValue.unix() : undefined)}
          />
        </Stack>
        <StyledButtonsContainer>
          <StyledButton variant="text" onClick={onApply}>
            {constants.apply}
          </StyledButton>
          {resetToDefault && (
            <StyledResetButton variant="outlined" onClick={handleReset}>
              {constants.reset}
            </StyledResetButton>
          )}
        </StyledButtonsContainer>
      </Popover>
    </Box>
  );
};
