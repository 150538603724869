import { Stack, Theme, styled } from '@mui/material';

export const StyledDescriptionsContainer = styled(Stack)({
  marginBottom: '6px',
  alignItems: 'center'
});

export const sxContainerStyles = (theme: Theme, maxHeight?: string) => ({
  '.MuiTypography-root': {
    lineHeight: '18px'
  },
  ...(maxHeight && {
    maxHeight: maxHeight,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '7px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.grey[300],
      borderRadius: '8px'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: theme.palette.grey[500]
    }
  })
});

export const sxWarningStyles = { margin: 'auto 0', width: '16px', height: '16px' };
export const sxTextStyles = { fontWeight: 900, marginRight: '5px' };
export const sxMarginStyles = { margin: '0 4px' };
