import { Stack, styled, alpha } from '@mui/material';

import { StyledContainer, StyledText } from '@/components/lib';

export const OverviewNoAppListContainer = styled(StyledContainer)({
  margin: '53px 0 0 50px',
  gap: 0,
  width: 'auto'
});

export const StyledTextContainer = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  justifyContent: 'center',

  '& span': {
    width: '118px'
  },
  '& svg': {
    color: theme.palette.grey[500]
  }
}));

export const StyledImageContainer = styled(Stack)(({ theme }) => ({
  background: alpha(theme.palette.primary.main, 0.2),
  width: '80px',
  height: '80px',
  borderRadius: '48px',
  justifyContent: 'center',
  alignItems: 'center',

  '& svg': {
    height: '48px',
    width: '48px'
  }
}));

export const StyledCardContainer = styled(Stack)(({ theme }) => ({
  border: `2px solid ${theme.palette.primary.main}`,
  background: theme.palette.common.white,
  width: '213px',
  height: '213px',
  borderRadius: '16px',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0 16px',
  gap: '20px',
  cursor: 'pointer',

  '&:hover': {
    background: alpha(theme.palette.primary.main, 0.04)
  }
}));

export const StyledCardsContainer = styled(Stack)({
  gap: '40px',
  flexWrap: 'wrap',
  flexDirection: 'row'
});

export const StyledTitleText = styled(StyledText)(({ theme }) => ({
  color: theme.palette.grey[500]
}));

export const StyledSubTitleText = styled(StyledText)(({ theme }) => ({
  color: theme.palette.grey[500],
  textAlign: 'left',
  marginTop: '40px'
}));

export const StyledCardTitleText = styled(StyledText)(({ theme }) => ({
  color: theme.palette.grey[500],
  textAlign: 'center'
}));

export const StyledDescriptionText = styled(StyledText)(({ theme }) => ({
  color: theme.palette.grey[500],
  maxWidth: '490px',
  textAlign: 'left',
  marginBottom: '55px'
}));
