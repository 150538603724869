import React from 'react';

import { StyledText } from '@/components/lib';
import { OverviewNoAppList } from '@/components/Overview/OverviewNoAppList/OverviewNoAppList';

import { StyledContainer } from './NoDataAvailable.styles';

import { constants } from './noDataAvailable.constants';

export const NoDataAvailable = ({ customTitle, isPage }: { customTitle?: string; isPage?: boolean }) => {
  if (isPage) {
    return <OverviewNoAppList />;
  }

  return (
    <StyledContainer>
      <StyledText text={customTitle || constants.noDataAvailable} type="h3" />
    </StyledContainer>
  );
};
