import React, { useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import { Alert, Snackbar } from '@mui/material';

import { sxStyles } from './GlobalPopUp.styles';

export const POPUP_TIMEOUT = 10000;

export const GlobalPopUp = () => {
  const [snackOpen, setSnackOpen] = useState(false);

  const { query, pathname, replace } = useRouter();

  const severity = () => {
    switch (query?.message_type) {
      case 'error':
        return 'error';
      case 'success':
        return 'success';
      default:
        return 'info';
    }
  };

  useEffect(() => {
    if (query?.message) {
      setSnackOpen(true);

      const updatedQuery = { ...query };

      const timeoutDeletion = setTimeout(() => {
        delete updatedQuery.message;
        delete updatedQuery.message_type;

        replace(
          {
            pathname,
            query: updatedQuery
          },
          undefined,
          {}
        );
      }, POPUP_TIMEOUT);

      return () => clearTimeout(timeoutDeletion);
    }
  }, [query]);

  if (query?.message && snackOpen) {
    return (
      <Snackbar
        open={snackOpen}
        autoHideDuration={POPUP_TIMEOUT}
        onClose={() => setSnackOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity={severity()} variant="filled" sx={sxStyles}>
          {query?.message}
        </Alert>
      </Snackbar>
    );
  }

  return <></>;
};
