import React from 'react';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import annotationPlugin from 'chartjs-plugin-annotation';

import { Box, BoxProps } from '@mui/material';

import { StyledTitleText, StyledSubtitleText } from './DistributionChart.styles';

import { getData, getOptions } from './DistributionChart.helpers';

ChartJS?.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  annotationPlugin
);

interface DistributionChartProps extends BoxProps {
  data: number[];
  title?: string;
  width?: number | string;
  height?: number;
  labels: number[] | string[];
  subtitle?: string;
  yAxisName?: string;
  condition?: number;
  isGoodAndBad?: boolean | null;
  average: { value: number; direction: 'horizontal' | 'vertical' };
}

export const DistributionChart = ({
  data,
  labels,
  title,
  subtitle,
  average,
  condition,
  yAxisName,
  width = 560,
  height = 320,
  isGoodAndBad,
  ...otherProps
}: DistributionChartProps) => (
  <Box width={width} key={title} {...otherProps}>
    <StyledTitleText text={title} type="body" />
    <Box width={width} key={title} height={height}>
      <Line
        options={getOptions(average, condition ?? NaN, yAxisName)}
        data={getData(data, labels, average?.direction, isGoodAndBad)}
      />
    </Box>
    <StyledSubtitleText text={subtitle} type="tiny" />
  </Box>
);
