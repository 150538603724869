import React from 'react';

import { ClearRounded, InsertDriveFileOutlined, LinkRounded } from '@mui/icons-material';
import { CircularProgress, useTheme } from '@mui/material';

import { StyledToolTip } from '@/components/lib';
import { DataContextItemContainer, DataContextItemText } from '../../../SamplesGeneration.styes';

export interface DataContextItemProps {
  handleDeleteClick: (id: string) => void;
  context: {
    src?: File;
    id: number | string;
    type: string;
    status: string;
  };
}

export const DataContextItem = ({ context, handleDeleteClick }: DataContextItemProps) => {
  const theme = useTheme();

  const color = () => {
    switch (context.status) {
      case 'failed':
        return theme.palette.error.main;
      case 'success':
        return theme.palette.success.main;
      default:
        return theme.palette.grey[400];
    }
  };

  return (
    <StyledToolTip text={context.status} placement="left">
      <DataContextItemContainer color={color()}>
        {context?.type === 'link' ? <LinkRounded /> : <InsertDriveFileOutlined />}
        <DataContextItemText text={context?.src?.name} />
        {context.status === 'load' ? (
          <CircularProgress size={24} color="info" />
        ) : context.status === 'success' ? (
          <ClearRounded onClick={() => handleDeleteClick(context?.id as string)} />
        ) : (
          <></>
        )}
      </DataContextItemContainer>
    </StyledToolTip>
  );
};
