import { Theme } from '@mui/material';

export const sxContainerStyles = (theme: Theme, disabled?: boolean) => ({
  ...(disabled && { cursor: 'default !important' }),
  '& svg': {
    ...(disabled && { color: theme.palette.grey[300] })
  },
  '& p': {
    ...(disabled && { color: theme.palette.grey[300] })
  }
});

export const sxStyles = (linkColor: string) => ({
  color: linkColor,
  textDecoration: 'none',
  fontWeight: 600,
  fontSize: '16px',
  height: '20px',
  lineHeight: '20px'
});
