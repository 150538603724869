import React from 'react';

import { CloudUploadOutlined, SettingsOutlined, FolderSpecialOutlined, OpenInNewOutlined } from '@mui/icons-material';

import { routes } from '@/helpers/routes';

import {
  OverviewNoAppListContainer,
  StyledImageContainer,
  StyledTitleText,
  StyledSubTitleText,
  StyledDescriptionText,
  StyledCardContainer,
  StyledCardTitleText,
  StyledCardsContainer,
  StyledTextContainer
} from './OverviewNoAppList.styles';

import { constants } from './overviewNoAppList.constants';

const { nothing, welcomeToDeepchecks, createAnApplication, manageUsers, description, getStartedDocumentation } =
  constants;

export const OverviewNoAppList = () => {
  const handleGoToOnboardingPage = () => window?.location?.replace(routes.onboarding);

  const handleGoToWorkspaceSettingsPage = () => window?.location?.replace(routes.workspaceSettings);

  const handleGoToDocumentation = () =>
    window.open('https://llmdocs.deepchecks.com/docs/getting-started-with-deepchecks', '_blank');

  return (
    <OverviewNoAppListContainer>
      <StyledTitleText text={welcomeToDeepchecks} type="h1" />
      <StyledSubTitleText text={nothing} type="h3" />
      <StyledDescriptionText text={description} type="h3" />
      <StyledCardsContainer>
        <StyledCardContainer onClick={handleGoToDocumentation}>
          <StyledImageContainer>
            <FolderSpecialOutlined fontSize="large" color="primary" />
          </StyledImageContainer>
          <StyledTextContainer>
            <StyledCardTitleText text={getStartedDocumentation} type="bodyBold" />
            <OpenInNewOutlined fontSize="small" />
          </StyledTextContainer>
        </StyledCardContainer>
        <StyledCardContainer onClick={handleGoToOnboardingPage}>
          <StyledImageContainer>
            <CloudUploadOutlined fontSize="large" color="primary" />
          </StyledImageContainer>
          <StyledCardTitleText text={createAnApplication} type="bodyBold" />
        </StyledCardContainer>
        <StyledCardContainer onClick={handleGoToWorkspaceSettingsPage}>
          <StyledImageContainer>
            <SettingsOutlined fontSize="large" color="primary" />
          </StyledImageContainer>
          <StyledCardTitleText text={manageUsers} type="bodyBold" />
        </StyledCardContainer>
      </StyledCardsContainer>
    </OverviewNoAppListContainer>
  );
};
