import React from 'react';

import { Stack } from '@mui/material';
import {
  AppsRounded,
  DarkModeOutlined,
  DescriptionOutlined,
  KeyOutlined,
  LightModeOutlined,
  OpenInNewRounded,
  SettingsOutlined
} from '@mui/icons-material';

import { StyledText } from '@/components/lib';

import { routes } from '@/helpers/routes';
import { UserSchema } from '@/helpers/services/api';

import {
  UserInfoAvatar,
  UserInfoMenuClickableContainer,
  UserInfoMenuContainer,
  UserInfoMenuDivider,
  UserInfoMenuHeaderContainer,
  UserInfoMenuLogout
} from '../UserInfo.styles';

const constants = {
  apiKey: 'API Key',
  logout: 'Log out',
  docs: 'Documentation',
  applications: 'Manage Applications',
  workspaceSettings: 'Workspace Settings',
  llmDocsLink: 'https://llmdocs.deepchecks.com/docs/getting-started-with-deepchecks',
  darkModeLabel: (darkMode: boolean) => `Switch To ${darkMode ? 'Light Mode' : 'Dark Mode'}`
};

interface UserInfoMenuProps {
  darkMode: boolean;
  user?: UserSchema;
  anchorEl: null | HTMLElement;
  handleClose: () => void;
  handleLogout: () => void;
  handleNavigate: (route: string) => void;
  setDarkMode: (darkMode: boolean) => void;
}

const { workspaceSettings, applications, docs, apiKey, logout, llmDocsLink, darkModeLabel } = constants;

export const UserInfoMenu = (props: UserInfoMenuProps) => {
  const { anchorEl, darkMode, user, handleNavigate, setDarkMode, handleClose, handleLogout } = props;

  const handleClick = (route: string) => {
    handleNavigate(route);
    handleClose();
  };

  const handleDarkModeChange = () => {
    setDarkMode(!darkMode);
    handleClose();
  };

  return (
    <UserInfoMenuContainer anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
      <UserInfoMenuHeaderContainer>
        <UserInfoAvatar alt={user?.email} />
        <Stack>
          <StyledText text={user?.name} type="bodyBold" />
          <StyledText text={user?.organization_name} type="small" />
        </Stack>
      </UserInfoMenuHeaderContainer>
      <UserInfoMenuDivider />
      <UserInfoMenuClickableContainer onClick={handleClick.bind(null, routes.workspaceSettings)}>
        <SettingsOutlined />
        <StyledText text={workspaceSettings} type="bodyBold" />
      </UserInfoMenuClickableContainer>
      <UserInfoMenuClickableContainer onClick={handleClick.bind(null, routes.config.applications)}>
        <AppsRounded />
        <StyledText text={applications} type="bodyBold" />
      </UserInfoMenuClickableContainer>
      <UserInfoMenuClickableContainer onClick={handleDarkModeChange}>
        {darkMode ? <LightModeOutlined /> : <DarkModeOutlined />}
        <StyledText text={darkModeLabel(darkMode)} type="bodyBold" />
      </UserInfoMenuClickableContainer>
      <a href={llmDocsLink} target="_blank" rel="noreferrer">
        <UserInfoMenuClickableContainer>
          <DescriptionOutlined />
          <StyledText text={docs} type="bodyBold" />
          <OpenInNewRounded fontSize="small" />
        </UserInfoMenuClickableContainer>
      </a>
      <UserInfoMenuClickableContainer onClick={handleClick.bind(null, routes.config.apiKey)}>
        <KeyOutlined />
        <StyledText text={apiKey} type="bodyBold" />
      </UserInfoMenuClickableContainer>
      <UserInfoMenuDivider />
      <UserInfoMenuLogout onClick={handleLogout} text={logout} type="tinyBold" />
    </UserInfoMenuContainer>
  );
};
