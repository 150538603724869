import React from 'react';

import { Stack } from '@mui/material';

import { Thumbs } from './../Button/Thumbs/Thumbs';
import { Text } from '../Text/Text';
import { Loader } from '../Loader/Loader';
import { paletteOptions } from '../../theme/palette';

import {
  LightTooltip,
  StyledDifferenceBarContainer,
  StyledDivider,
  StyledHorizontalDivider,
  StyledNoDataText,
  StyledScoreDivider,
  StyledSliderContainer,
  StyledTooltipContent,
  StyledTooltipScoreContent,
  StyledTooltipVersionContent,
  sxAnnotationContainerStyles,
  sxFirstEstimatedTriangleStyles,
  sxFirstTriangleStyles,
  sxMainTrianglesStyles,
  sxSecondContainerStyles,
  sxSecondEstimatedTriangleStyles,
  sxSecondTriangleStyles,
  sxStyles,
  sxWhiteContainerStyles
} from './DifferenceBar.styles';

const constants = {
  defaultMost: 'Most Similar >',
  defaultLeast: '< Least Similar',
  defaultTitle: 'Similarity Scores',
  noSimilarityData: 'No similarity data'
};

const { defaultMost, defaultLeast, defaultTitle, noSimilarityData } = constants;

export type goodAndBad = 'Good Annotated' | 'Bad Annotated' | 'Good Estimated' | 'Bad Estimated' | 'Not Annotated';

export interface DataInterface {
  score?: number | string;
  secondItemScore?: number | string;
  firstItemAnnotationType?: goodAndBad;
  secondItemAnnotationType?: goodAndBad;
  onClick: () => void;
}

export interface DifferenceBarProps {
  firstVersionName: string;
  firstVersionEnv?: string;
  secondVersionName?: string;
  secondVersionEnv?: string;
  title?: string;
  least?: string;
  most?: string;
  fullWidth?: boolean;
  data: DataInterface[];
  isGoodAndBadIcon?: boolean;
  isTwoScore?: boolean;
  isHideMostLeast?: boolean;
  isLoading?: boolean;
  currentPage: number;
}

export const DifferenceBar = (props: DifferenceBarProps) => {
  const {
    data,
    title,
    isGoodAndBadIcon,
    least,
    most,
    firstVersionName,
    firstVersionEnv,
    secondVersionName,
    secondVersionEnv,
    fullWidth,
    isTwoScore,
    isHideMostLeast,
    isLoading,
    currentPage
  } = props;

  const minScore = Math.min(...data.map(item => (typeof item.score === 'number' ? item.score : 0)));

  const isSimilarityScore = !isTwoScore && !isGoodAndBadIcon;

  const getCurAnnotatedBackground = (itemType: string) => {
    switch (itemType) {
      case 'Good Annotated':
        return 'rgba(18, 175, 105, 0.5)';
      case 'Bad Annotated':
        return 'rgba(238, 57, 93, 0.5)';
      case 'Not Annotated':
        return '#E2E2EE';
      default:
        return '#ffffff';
    }
  };

  const getCurIcon = (itemType?: string) => {
    if (!itemType) {
      return <></>;
    }
    switch (itemType) {
      case 'Good Annotated':
        return <Thumbs color="green" sx={sxStyles} />;
      case 'Bad Annotated':
        return <Thumbs color="red" thumbDirection="down" sx={sxStyles} />;
      case 'Good Estimated':
        return <Thumbs color="green" outlined label="Est." sx={sxStyles} />;
      case 'Bad Estimated':
        return <Thumbs color="red" thumbDirection="down" outlined label="Est." sx={sxStyles} />;
      default:
        return <Thumbs isUnknown sx={sxStyles} />;
    }
  };

  const getCurBorder = (itemType?: string) => {
    if (!isGoodAndBadIcon || !itemType || (itemType && !itemType.includes('Estimated'))) {
      return 'none';
    } else if (isGoodAndBadIcon && itemType) {
      return `3px solid ${itemType.includes('Good') ? 'rgba(18, 175, 105, 0.5)' : 'rgba(238, 57, 93, 0.5)'}`;
    }
  };

  const getCurColorValue = (score?: string | number) => {
    const curValue = (minScore || 0.1) / (score && typeof +score === 'number' ? (+score > 0.1 ? +score : 0.1) : 0);

    if (!isFinite(curValue)) {
      return 0.1;
    } else if (curValue < 0.1) {
      return curValue * 10;
    } else {
      return curValue || 0;
    }
  };

  return (
    <StyledDifferenceBarContainer width={fullWidth ? '100%' : '400px'}>
      <Stack
        flexDirection="row"
        justifyContent={isHideMostLeast ? 'center' : 'space-between'}
        alignItems="center"
        margin="0px 0px 7px 0px"
      >
        {!isHideMostLeast && <Text text={least || defaultLeast} type="tiny" />}
        <Text text={title || defaultTitle} type="bodyBold" />
        {!isHideMostLeast && <Text text={most || defaultMost} type="tiny" />}
      </Stack>
      <StyledSliderContainer gridTemplateColumns={`repeat(${data?.length},32px)`}>
        {isLoading ? (
          <Loader />
        ) : data.length < 1 ? (
          <StyledNoDataText text={noSimilarityData} type="bodyBold" />
        ) : (
          data?.map((item, index) => (
            <LightTooltip
              key={index}
              title={
                <StyledTooltipContent>
                  <StyledTooltipVersionContent>
                    <Text text={firstVersionName} type="tinyBold" />
                    <StyledDivider orientation="vertical" />
                    <Text text={firstVersionEnv} type="tiny" />
                  </StyledTooltipVersionContent>
                  {isTwoScore && <Text text={item.score} type="bodyBold" />}
                  {isGoodAndBadIcon && getCurIcon(item.firstItemAnnotationType)}
                  {(isTwoScore || isGoodAndBadIcon) && <StyledHorizontalDivider orientation="horizontal" />}
                  {isSimilarityScore && (
                    <StyledTooltipScoreContent>
                      <StyledScoreDivider orientation="horizontal" />
                      <Text text={item.score} type="bodyBold" />
                      <StyledScoreDivider orientation="horizontal" />
                    </StyledTooltipScoreContent>
                  )}
                  <StyledTooltipVersionContent>
                    <Text text={secondVersionName} type="tinyBold" />
                    <StyledDivider orientation="vertical" />
                    <Text text={secondVersionEnv} type="tiny" />
                  </StyledTooltipVersionContent>
                  {isTwoScore && <Text text={item.secondItemScore} type="bodyBold" />}
                  {isGoodAndBadIcon && getCurIcon(item.secondItemAnnotationType)}
                </StyledTooltipContent>
              }
              arrow
              placement="bottom"
            >
              {isGoodAndBadIcon && item.firstItemAnnotationType && item.secondItemAnnotationType ? (
                <Stack sx={sxAnnotationContainerStyles(currentPage, index, paletteOptions)} onClick={item.onClick}>
                  {item.firstItemAnnotationType.includes('Estimated') && (
                    <>
                      <Stack sx={sxFirstTriangleStyles(currentPage, index, item, getCurBorder)} />
                      <Stack sx={sxSecondTriangleStyles(currentPage, index, item, getCurBorder)} />
                    </>
                  )}
                  <Stack
                    sx={sxWhiteContainerStyles(currentPage, index, item, getCurBorder, getCurAnnotatedBackground)}
                  />
                  <Stack
                    sx={sxSecondContainerStyles(currentPage, index, item, getCurBorder, getCurAnnotatedBackground)}
                  />
                  {item.secondItemAnnotationType.includes('Estimated') && (
                    <>
                      <Stack sx={sxFirstEstimatedTriangleStyles(currentPage, index, item, getCurBorder)} />
                      <Stack sx={sxSecondEstimatedTriangleStyles(currentPage, index, item, getCurBorder)} />
                    </>
                  )}
                </Stack>
              ) : (
                <Stack
                  sx={sxMainTrianglesStyles(
                    currentPage,
                    index,
                    item,
                    isTwoScore || false,
                    minScore,
                    paletteOptions,
                    getCurColorValue
                  )}
                  onClick={item.onClick}
                />
              )}
            </LightTooltip>
          ))
        )}
      </StyledSliderContainer>
    </StyledDifferenceBarContainer>
  );
};
