import React, { useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Select, FormControl, MenuItem, styled, Stack } from '@mui/material';

import { Text } from '../../Text/Text';
import { Input } from '../../Input/Input';

import { sxOutlinedDropdownStyles } from '../Dropdown.styles';

export interface OutlinedDropdownProps {
  label?: string;
  disabled?: boolean;
  dataTestid?: string;
  searchField?: boolean;
  size?: 'small' | 'medium';
  value: string | number | undefined;
  setValue: (value: string | number) => void;
  data: { value: string | number; label: string }[];
}

export const OutlinedDropdown = (props: OutlinedDropdownProps) => {
  const { label, disabled, dataTestid, searchField, size, value, setValue, data } = props;

  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const filteredData = data?.filter(item => item?.label?.toLowerCase().includes(searchQuery?.toLowerCase()));

  const handleSelectClose = () => {
    setOpen(false);
  };

  return (
    <StyledFormControl>
      <StyledInputLabel text={label} type="small" />
      <Select
        size={size}
        disabled={disabled}
        value={value || ''}
        data-testid={dataTestid}
        IconComponent={ArrowDropDownIcon}
        MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
        sx={sxOutlinedDropdownStyles}
        open={open}
        onClose={handleSelectClose}
        onOpen={() => setOpen(true)}
        renderValue={selected => {
          const selectedItem = data.find(item => item.value === selected);
          return selectedItem ? selectedItem.label : '';
        }}
      >
        {searchField && (
          <Stack padding={1}>
            <Input
              value={searchQuery}
              placeholder="Search..."
              onClick={e => e.stopPropagation()}
              onChange={e => setSearchQuery(e.target.value)}
              autoFocus
            />
          </Stack>
        )}
        <Stack>
          {filteredData.map(({ value, label }) => (
            <SelectPrimaryItem
              value={value}
              key={value}
              onClick={() => {
                setValue(value);
                handleSelectClose();
              }}
            >
              {label}
            </SelectPrimaryItem>
          ))}
        </Stack>
      </Select>
    </StyledFormControl>
  );
};

export const SelectPrimaryItem = styled(MenuItem)({
  '&.Mui-selected': {
    backgroundColor: 'transparent'
  }
});

export const StyledFormControl = styled(FormControl)({
  minWidth: 160
});

export const StyledInputLabel = styled(Text)({
  margin: '3px'
});
