export const sxContainerStyles = {
  '.Mui-expanded': { margin: 0, background: 'none' },
  '.MuiAccordion-root': { background: 'none' }
};
export const sxAccordionStyles = (grey: string) => ({
  color: grey,
  boxShadow: 'none',
  ':before': { background: 'none' }
});
export const sxPaddingStyles = { padding: '0' };
export const sxDividerStyles = (grey: string) => ({
  background: grey,
  width: '100%',
  height: '1px',
  margin: 'auto 12px'
});
