import React, { useState } from 'react';

import { UserInfoMenu } from './UserInfoMenu/UserInfoMenu';

import { UserInfoAvatar, UserInfoAvatarContainer, UserInfoContainer, UserInfoText } from './UserInfo.styles';

import { UserSchema } from '@/helpers/services/api';
import { resetMixpanel } from '@/helpers/services/mixPanel';
import { getAuthUrl, getCustomLogout } from '@/helpers/services/auth';

interface UserInfoProps {
  darkMode: boolean;
  user?: UserSchema;
  iconsOnly?: boolean;
  handleNavigate: (route: string) => void;
  setDarkMode: (darkMode: boolean) => void;
  setUser: (user: UserSchema | undefined) => void;
}

export const UserinfoSection = (props: UserInfoProps) => {
  const { iconsOnly, darkMode, user, handleNavigate, setDarkMode, setUser } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    getCustomLogout()?.then(() => {
      setUser(undefined);
      resetMixpanel();
      window?.location?.replace(getAuthUrl());
    });
  };

  const handleClose = () => setAnchorEl(null);

  return (
    <UserInfoContainer>
      <UserInfoAvatarContainer onClick={handleClick}>
        <UserInfoAvatar alt={user?.email} />
        {!iconsOnly && <UserInfoText text={user?.name ?? user?.email} type="bodyBold" />}
      </UserInfoAvatarContainer>
      <UserInfoMenu
        user={user}
        anchorEl={anchorEl}
        darkMode={darkMode}
        setDarkMode={setDarkMode}
        handleClose={handleClose}
        handleLogout={handleLogout}
        handleNavigate={handleNavigate}
      />
    </UserInfoContainer>
  );
};
