import React from 'react';

import { Stack } from '@mui/material';
import { HelpOutline } from '@mui/icons-material';

import { Text } from '../../../Text/Text';
import { Thumbs } from '../../../Button/Thumbs/Thumbs';
import { ThemeProvider } from '../../../../theme/ThemeProvider';

import {
  StyledContainer,
  StyledLabelText,
  StyledRowContainer,
  StyledScoreText,
  StyledSeparator
} from './ComboChartTooltip.styles';

import { comboBarsColors } from '../comboChart.helpers';
import dayjs from 'dayjs';

interface ComboChartTooltipProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pointSchema: any;
}

const constants = {
  scoreLabel: `Score`,
  seeInteractions: 'See Interactions',
  annotationLabels: {
    user: 'User',
    unknown: 'Unknown',
    estimated: 'Estimated'
  },
  nOfInteractions: (n: number) => `${n} Interactions`,
  scoreNumber: (score: number) => ` ${Math.round(Number(score || 0) * 100)}%`
};

const { scoreLabel, annotationLabels, scoreNumber, nOfInteractions } = constants;

export const ComboChartTooltip = ({ pointSchema }: ComboChartTooltipProps) => {
  const { per_type_results, score, amount, end_time, start_time } = pointSchema;

  const nUser = Number(per_type_results?.good?.user?.amount || 0) + Number(per_type_results?.bad?.user?.amount || 0);
  const nEstimated =
    Number(per_type_results?.good?.estimated?.amount || 0) + Number(per_type_results?.bad?.estimated?.amount || 0);
  const nUnknown =
    Number(per_type_results?.unknown?.user?.amount || 0) + Number(per_type_results?.unknown?.estimated?.amount || 0);

  const annotationsScoreList = [
    {
      Icon: <Thumbs thumbDirection="up" color="green" />,
      percentage: Number(per_type_results?.good?.user?.percentage || 0)
    },
    {
      Icon: <Thumbs thumbDirection="up" color="green" outlined />,
      percentage: Number(per_type_results?.good?.estimated?.percentage || 0)
    },
    {
      Icon: <Thumbs thumbDirection="down" color="red" />,
      percentage: Number(per_type_results?.bad?.user?.percentage || 0)
    },
    {
      Icon: <Thumbs thumbDirection="down" color="red" outlined />,
      percentage: Number(per_type_results?.bad?.estimated?.percentage || 0)
    },
    {
      Icon: <HelpOutline color="info" />,
      percentage:
        Number(per_type_results?.unknown?.estimated?.percentage || 0) +
        Number(per_type_results?.unknown?.user?.percentage || 0)
    }
  ];

  return (
    <ThemeProvider>
      <StyledContainer>
        <Text text={`${dayjs(start_time).format('DD/MM LT')} - ${dayjs(end_time).format('DD/MM LT')}`} type="tiny" />
        <StyledSeparator />
        <span>
          <StyledLabelText text={scoreLabel} type="bodyBold" />
          <StyledScoreText text={scoreNumber(Number(score))} type="bodyBold" />
        </span>
        <StyledRowContainer>
          {annotationsScoreList.map(({ Icon, percentage }, i) => (
            <Stack key={i} sx={{ opacity: percentage ? 1 : 0.5 }}>
              {Icon}
              <Text text={`${percentage}%`} type="tinyBold" />
            </Stack>
          ))}
        </StyledRowContainer>
        <StyledSeparator />
        <StyledLabelText text={nOfInteractions(amount)} type="bodyBold" />
        <StyledRowContainer>
          <Stack>
            <Text text={nUser} type="tinyBold" color={comboBarsColors.blue} />
            <Text text={annotationLabels.user} type="tinyBold" color={comboBarsColors.blue} />
          </Stack>
          <Stack>
            <Text text={nEstimated} type="tinyBold" color={comboBarsColors.lightBlue} />
            <Text text={annotationLabels.estimated} type="tinyBold" color={comboBarsColors.lightBlue} />
          </Stack>
          <Stack>
            <Text text={nUnknown} type="tinyBold" color={comboBarsColors.grey} />
            <Text text={annotationLabels.unknown} type="tinyBold" color={comboBarsColors.grey} />
          </Stack>
        </StyledRowContainer>
      </StyledContainer>
    </ThemeProvider>
  );
};
