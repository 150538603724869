import React, { ReactNode } from 'react';

import { Warning } from '@mui/icons-material';
import { Stack, Tooltip, useTheme } from '@mui/material';

import { StyledText } from '../../..';
import { Container } from '../Container';

import {
  StyledDescriptionsContainer,
  sxContainerStyles,
  sxMarginStyles,
  sxTextStyles,
  sxWarningStyles
} from './ToolTip.styles';

export interface ToolTipProps {
  text?: string;
  row?: boolean;
  arrow?: boolean;
  error?: boolean;
  maxHeight?: string;
  children: React.ReactElement;
  placement?: 'bottom' | 'top' | 'left' | 'right';
  link?: { label?: string; href?: string; onClickOverride?: () => void };
  range?: { min: number | undefined; max: number | undefined };
  descriptions?: ReactNode | string | { text: string; info: string; icon: ReactNode }[];
}

export const ToolTip = ({
  text,
  descriptions,
  error = false,
  children,
  arrow = true,
  placement = 'top',
  link,
  range,
  maxHeight
}: ToolTipProps) => {
  const theme = useTheme();

  const linkColor = theme.palette.primary.main;
  const showLink = (link?.label && link?.onClickOverride) || (link?.href && link?.label);

  return (
    <Tooltip
      title={
        text && (
          <Container padding="8px" gap="12px" sx={sxContainerStyles(theme, maxHeight)}>
            <Container padding="0" margin="0 auto">
              {error && <Warning fontSize="small" sx={sxWarningStyles} />}
              <StyledText text={text} type="small" color={theme.palette.common.white} />
              {range && (
                <Stack direction="row">
                  <StyledText text="Range:" type="small" color={theme.palette.common.white} sx={sxTextStyles} />
                  <StyledText
                    text={`min ${range.min ?? '-Inf'} - max ${range.max ?? 'Inf'}`}
                    type="small"
                    color={theme.palette.common.white}
                  />
                </Stack>
              )}
              {descriptions &&
                (Array.isArray(descriptions) ? (
                  <Stack direction="column">
                    {descriptions.map((el, index) => (
                      <StyledDescriptionsContainer direction="row" key={index}>
                        {el.icon}
                        <StyledText
                          text={el.text}
                          type="small"
                          color={theme.palette.common.white}
                          sx={sxMarginStyles}
                        />
                        <StyledText text={el.info} type="small" color={theme.palette.common.white} />
                      </StyledDescriptionsContainer>
                    ))}
                  </Stack>
                ) : (
                  <Stack direction="row">
                    <StyledText
                      text={descriptions}
                      type={typeof descriptions === 'string' ? 'tiny' : 'small'}
                      color={theme.palette.common.white}
                    />
                  </Stack>
                ))}
            </Container>
            {showLink && (
              <a
                rel={link?.onClickOverride ? ' ' : 'noreferrer'}
                target={link?.onClickOverride ? '_self' : '_blank'}
                href={link?.onClickOverride ? undefined : link.href}
                onClick={link?.onClickOverride ? link?.onClickOverride : () => undefined}
                style={{
                  textDecoration: 'none',
                  color: linkColor,
                  fontSize: '12px',
                  fontWeight: 900,
                  cursor: 'pointer'
                }}
              >
                {link.label}
              </a>
            )}
          </Container>
        )
      }
      arrow={arrow}
      placement={placement}
    >
      {children}
    </Tooltip>
  );
};
