import React, { useEffect } from 'react';

import Image from 'next/image';
import { useRouter } from 'next/router';

import { CloudUploadOutlined } from '@mui/icons-material';
import { Stack, useMediaQuery, useTheme } from '@mui/material';

import { SamplesGeneration } from '@/components/shared/SamplesGeneration/SamplesGeneration';
import { StyledNavLink } from '@/components/lib';

import { EnvType, UserSchema, useListApps } from '@/helpers/services/api';
import { routes, sidebarRoutes } from '@/helpers/routes';
import { getUserPreferences, setStorageItem, storageKeys } from '@/helpers/utils/localStorage';

import logoImg from '@/public/assets/logo.svg';
import darkLogoImg from '@/public/assets/darkLogo.svg';
import iconLogoImg from '@/public/favicon.ico';

import { UserinfoSection } from './UserInfo/UserinfoSection';

import { SidebarIconLogoContainer } from './Sidebar.styles';
import {
  SidebarButton,
  SidebarContainer,
  SidebarSeparator /* SidebarOpenIcon, SidebarCloseIcon,*/
} from '../Layout.styles';

const constants = { imgAlt: 'logo illustration', uploadSamplesLabel: 'Upload Data' };

interface SidebarProps {
  darkMode: boolean;
  iconsOnly: boolean;
  user?: UserSchema;
  setIconsOnly: React.Dispatch<React.SetStateAction<boolean>>;
  setDarkMode: (darkMode: boolean) => void;
  setUser: (user: UserSchema | undefined) => void;
}

export const Sidebar = ({ user, setUser, darkMode, setDarkMode, iconsOnly, setIconsOnly }: SidebarProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { pathname, push } = useRouter();

  const { darkMode: darkModeStorage, activeEnvs } = getUserPreferences();
  const { data, isLoading } = useListApps();

  const isActiveRoute = (currentRoute: string) => pathname === currentRoute;

  const handleLinkClick = (route: string) => push(route);

  const handleNavigate = (route: string) => {
    if (!isLoading && !data?.length) {
      window?.location?.replace(route);
    } else {
      push(route);
    }
  };

  // const handleChangeBar = () => {
  //   setIconsOnly(!iconsOnly);
  //   setStorageItem(storageKeys?.userPreferences, {
  //     lightBar: !iconsOnly,
  //     darkMode: darkModeStorage,
  //     activeEnvs: activeEnvs
  //   });
  // };

  useEffect(() => {
    if (isMobile) {
      setIconsOnly(true);
      setStorageItem(storageKeys?.userPreferences, {
        lightBar: true,
        darkMode: darkModeStorage,
        activeEnvs: activeEnvs
      });
    }
  }, [isMobile]);

  return (
    <SidebarContainer type="bar" iconsOnly={iconsOnly}>
      {/* !isMobile &&
        (iconsOnly ? <SidebarOpenIcon onClick={handleChangeBar} /> : <SidebarCloseIcon onClick={handleChangeBar} />) */}
      {iconsOnly ? (
        <SidebarIconLogoContainer>
          <Image width={30} height={30} loading="eager" src={iconLogoImg} alt={constants.imgAlt} />
        </SidebarIconLogoContainer>
      ) : (
        <Image
          width={200}
          height={80}
          loading="eager"
          priority={true}
          alt={constants.imgAlt}
          src={darkMode ? darkLogoImg : logoImg}
        />
      )}
      {sidebarRoutes.map((route, i) => (
        <Stack key={route.linkLabel}>
          <StyledNavLink
            icon={route.icon}
            linkLabel={route.linkLabel}
            activeIcon={route.activeIcon}
            isActive={isActiveRoute(route.route)}
            onClick={() => handleLinkClick(route.route)}
            iconsOnly={iconsOnly}
            disabled={!isLoading && !data?.length}
          />
          {i === 1 ? <SidebarSeparator /> : <></>}
        </Stack>
      ))}
      <SidebarButton
        startIcon={!iconsOnly && <CloudUploadOutlined />}
        label={iconsOnly ? <CloudUploadOutlined /> : constants.uploadSamplesLabel}
        width={iconsOnly ? '24px' : 'auto'}
        onClick={() => handleNavigate(routes.onboarding)}
      />
      <SamplesGeneration iconsOnly={iconsOnly} isPentestActive={activeEnvs.includes(EnvType['PENTEST'])} />
      <UserinfoSection
        user={user}
        darkMode={darkMode}
        iconsOnly={iconsOnly}
        setUser={setUser}
        setDarkMode={setDarkMode}
        handleNavigate={handleNavigate}
      />
    </SidebarContainer>
  );
};
