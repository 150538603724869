import { PaletteOptions as MUIPaletteOptions } from '@mui/material';

interface PaletteOptions extends MUIPaletteOptions {
  severity?: {
    low: string;
    medium: string;
    high: string;
    critical: string;
  };
}

export const paletteOptions: PaletteOptions = {
  mode: 'light',
  common: {
    black: '#070914', // Black
    white: '#FFFFFF' // White
  },
  primary: {
    main: '#9159F7', // Purple
    light: '#F0EBFF' // Light Purple
  },
  secondary: {
    main: '#0056B3', // Blue
    light: '#42CBE9' // Light Blue
  },
  error: {
    main: '#FC636B' // Red
  },
  success: {
    main: '#12AF69', // Green
    light: '#B8E24A' // Light green
  },
  info: {
    main: '#B2BCC4' // Grey[300]
  },
  grey: {
    50: '#FDFDFE',
    100: '#F8F8FC',
    200: '#EEEEF7',
    300: '#C8C9DF',
    400: '#9D9DBE',
    500: '#68688D',
    600: '#393960',
    700: '#120A47',
    800: '#171724'
  },
  severity: {
    low: '#B2BCC4', // Grey[300]
    medium: '#FFC900', // Yellow
    high: '#FD742D', // Orange
    critical: '#FC636B' // Red
  }
};

export const darkModePalette: PaletteOptions = {
  mode: 'dark',
  common: {
    black: '#FFFFFF', // White
    white: '#1a1825' // Black
  },
  primary: {
    main: '#9159F7' // Purple
  },
  secondary: {
    main: '#0056B3', // Blue
    light: '#42CBE9' // Light Blue
  },
  error: {
    main: '#FC636B' // Red
  },
  success: {
    main: '#12AF69', // Green
    light: '#B8E24A' // Light Green
  },
  info: {
    main: '#B2BCC4' // Grey[300]
  },
  grey: {
    50: '#1B1B26',
    100: '#2A2A38',
    200: '#383850',
    300: '#8484A6',
    400: '#9A9ABD',
    500: '#B5B5D5',
    600: '#D0D0E7',
    700: '#E7E7F5',
    800: '#FFFFFF'
  },
  severity: {
    low: '#B2BCC4', // Grey[300]
    medium: '#FFC900', // Yellow
    high: '#FD742D', // Orange
    critical: '#FC636B' // Red
  }
};
