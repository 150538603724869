import { Box, styled } from '@mui/material';

export const StyledScoresBox = styled(Box)(({ theme }) => ({
  gap: '34px',
  display: 'flex',
  margin: '16px auto',
  textAlign: 'center',
  flexDirection: 'column',
  justifyContent: 'center',

  '.MuiTypography-root': { color: theme.palette.grey[300] }
}));

export const StyledDoughnutChartContainer = styled(Box)({
  width: '100%',
  display: 'grid',
  margin: '0 auto',
  gridTemplateColumns: 'auto 90px'
});

export const doughnutChartImageStyle: React.CSSProperties = {
  left: '0px',
  top: '200px',
  zIndex: '-1',
  cursor: 'pointer',
  position: 'absolute'
};

export const sxStyles = { cursor: 'pointer', '.MuiTypography-root': { display: 'block' } };
