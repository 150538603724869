import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { Box, OutlinedInput, InputProps as MUIInputProps, styled } from '@mui/material';

import { Text } from '../Text/Text';

import { sxStyles } from './Input.styles';

export const StyledInputLabel = styled(Text)({
  margin: '3px'
});

const StyledOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  borderRadius: '5px',
  color: theme.palette.grey[600],

  '& input': { padding: '10px 12px' },
  '& fieldset': { borderColor: theme.palette.grey[300] },
  '&:hover fieldset': {
    borderColor: `${theme.palette.grey[300]} !important`,
    borderWidth: '2px !important'
  },
  '&.Mui-focused fieldset': {
    borderColor: `${theme.palette.primary.main} !important`,
    borderWidth: '1px !important'
  }
}));

const StyledSearchIcon = styled(SearchIcon)(({ theme }) => ({
  color: theme.palette.grey[400]
}));

export interface InputProps extends MUIInputProps {
  value: string;
  label?: string;
  searchField?: boolean;
  onCloseIconClick?: () => void;
}

export const Input = (props: InputProps) => {
  const { value, onCloseIconClick, searchField, label, ...otherProps } = props;

  const id = label ? 'id ' + label : label;

  const CloseIconAdornment = <CloseIcon onClick={onCloseIconClick} cursor="pointer" sx={sxStyles} />;

  return (
    <Box>
      {label && <StyledInputLabel text={label} type="small" />}
      <StyledOutlinedInput
        id={id}
        value={value}
        endAdornment={
          searchField ? (
            value ? (
              CloseIconAdornment
            ) : (
              <StyledSearchIcon />
            )
          ) : (
            value && onCloseIconClick && CloseIconAdornment
          )
        }
        fullWidth
        {...otherProps}
      />
    </Box>
  );
};
