import React, { useEffect, useState } from 'react';

import Head from 'next/head';
import { AppProps } from 'next/app';

import { QueryClient, QueryClientProvider } from 'react-query';

import { Layout } from '@/components/Layout/Layout';
import { GlobalPopUp } from '@/components/shared/GlobalPopUp/GlobalPopUp';

import { StyledThemeProvider } from '@/components/lib';

import { getUserPreferences, setStorageItem, storageKeys } from '@/helpers/utils/localStorage';

import '../components/lib/assets/css/fonts.css';

const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false } } });

const TITLE = 'Deepchecks LLM Evaluation';

export default function App({ Component, pageProps }: AppProps) {
  const { lightBar, darkMode: darkModeStorage, activeEnvs } = getUserPreferences();

  const [darkMode, setDarkMode] = useState(darkModeStorage);

  useEffect(() => setStorageItem(storageKeys.userPreferences, { lightBar, darkMode, activeEnvs }), [darkMode]);

  if (
    typeof window !== 'undefined' &&
    window?.location !== window?.parent?.location &&
    !window.parent?.location.hash.includes('file=cypress')
  ) {
    return null;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Head>
        <link rel="manifest" href="/manifest.json" />
        <title>{TITLE}</title>
      </Head>
      <StyledThemeProvider darkMode={darkMode}>
        <GlobalPopUp />
        <Layout darkMode={darkMode} setDarkMode={setDarkMode}>
          <Component {...pageProps} />
        </Layout>
      </StyledThemeProvider>
    </QueryClientProvider>
  );
}
