export const handleGetTimeByEnv = () => {
  const origin = typeof window !== 'undefined' && window.location.origin ? window.location.origin : '';

  if (origin.includes('staging')) {
    return 1440 * 60 * 1000;
  } else if (origin.includes('app')) {
    return 480 * 60 * 1000;
  }

  return 15 * 60 * 1000;
};
