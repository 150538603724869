import { Box, styled, Button, alpha } from '@mui/material';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';

const OPACITY = 0.4;

const AppFiltersTimeFilterButton = styled(Button)(({ theme }) => ({
  fontWeight: 500,
  margin: '0',
  minWidth: '240px',
  boxShadow: 'none',
  borderRadius: '8px',
  color: theme.palette.grey[500],
  justifyContent: 'space-between',
  backgroundColor: theme.palette.grey[200],

  '&:hover': {
    boxShadow: 'none',
    backgroundColor: theme.palette.grey[200]
  },

  '.MuiSvgIcon-root': { margin: '1.5px 0', width: '24px', height: '24px' }
}));

const sharedDatePickerStyles = (primaryColor: string, secondaryColor: string) => ({
  '& .MuiPickersToolbar-root': {
    display: 'none'
  },

  '& .MuiPickersCalendarHeader-label': {
    fontWeight: 600,
    color: secondaryColor
  },

  '& .MuiButtonBase-root': {
    fontWeight: 700,
    color: secondaryColor
  },

  '& .MuiPickersLayout-toolbar': {
    backgroundColor: primaryColor,
    paddingBottom: 0,

    '& > span': {
      display: 'none'
    },

    '& .MuiTypography-root': {
      color: secondaryColor
    },

    '& .Mui-selected': {
      opacity: 1
    }
  },

  '& .MuiDateCalendar-root': {
    backgroundColor: primaryColor,

    '& .MuiSvgIcon-root, .MuiTypography-root, .MuiPickersCalendarHeader-label': {
      color: secondaryColor
    },

    '& .MuiDayCalendar-monthContainer': {
      '& .MuiButtonBase-root': {
        color: secondaryColor
      },

      '& .MuiButtonBase-root:hover, .MuiPickersDay-today, .Mui-selected': {
        border: '1px solid',
        borderColor: secondaryColor,
        fontWeight: 800
      },

      '& .MuiPickersDay-today': {
        background: secondaryColor,
        color: primaryColor
      },

      '& .Mui-disabled': {
        opacity: OPACITY
      }
    }
  },

  '& .MuiPickersFadeTransitionGroup-root, .MuiYearCalendar-root': {
    maxHeight: '280px'
  },

  '& .MuiPickersYear-root': {
    '& .MuiPickersYear-yearButton': {
      color: alpha(secondaryColor, OPACITY),
      border: '1px solid transparent',

      '&:hover': {
        borderColor: secondaryColor,
        backgroundColor: 'transparent'
      }
    },

    '& .Mui-selected': {
      color: secondaryColor
    }
  },

  '& .MuiDialogActions-root': {
    display: 'none'
  }
});

const StyledStaticDatePicker = styled(StaticDatePicker)(({ theme }) => ({
  ...sharedDatePickerStyles(theme.palette.primary.main, theme.palette.common.white),

  '& .MuiTypography-root': {
    opacity: 1
  }
})) as typeof StaticDatePicker;

const StyledButtonsContainer = styled(Box)(({ theme }) => ({
  gap: '8px',
  padding: '16px 0 8px',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'center',
  backgroundColor: theme.palette.primary.main
}));

const StyledButton = styled(Button)(({ theme }) => ({
  height: '32px',
  fontWeight: 700,
  fontSize: '14px',
  minWidth: '70px',
  marginBottom: '8px',
  width: 'fit-content',
  borderRadius: '28px',
  border: '1px solid transparent',
  color: theme.palette.primary.main,
  transition: 'all 0.3s ease-in-out',
  backgroundColor: theme.palette.common.white,

  '&:hover': { opacity: 0.8 }
}));

const StyledResetButton = styled(Button)(({ theme }) => ({
  height: '32px',
  fontWeight: 700,
  fontSize: '14px',
  minWidth: '70px',
  marginBottom: '8px',
  width: 'fit-content',
  borderRadius: '28px',
  border: `1px solid ${theme.palette.common.white}`,
  color: theme.palette.common.white,
  transition: 'all 0.3s ease-in-out',
  backgroundColor: theme.palette.primary.main,

  '&:hover': { opacity: 0.8 }
}));

export { StyledButton, AppFiltersTimeFilterButton, StyledResetButton, StyledButtonsContainer, StyledStaticDatePicker };
