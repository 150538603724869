import React from 'react';

import { TypographyProps, Typography } from '@mui/material';

import { Text, TextTypes } from '../Text';

import { sharedStyles, sxStyles } from './HighlightedText.styles';

interface HighlightedTextProps extends TypographyProps {
  beforeHighlightedText?: string;
  highlightedText: string;
  afterHighlightedText?: string;
  type?: TextTypes;
  highlightedTextType?: TextTypes;
  highlightedTextBold?: boolean;
  onHighlightedTextClick?: (e: React.SyntheticEvent) => void;
}

export const HighlightedText = ({
  beforeHighlightedText = '',
  highlightedText,
  onHighlightedTextClick,
  afterHighlightedText = '',
  type,
  highlightedTextType,
  highlightedTextBold,
  color,
  ...otherProps
}: HighlightedTextProps) => {
  const isOnclick = !!onHighlightedTextClick;

  return (
    <Typography {...otherProps}>
      <Text component="span" text={beforeHighlightedText} type={type} sx={sharedStyles} color={color} />
      <Text
        component="span"
        text={highlightedText}
        type={highlightedTextType || type}
        onClick={onHighlightedTextClick}
        sx={theme => sxStyles(theme, isOnclick, highlightedTextBold)}
      />
      <Text component="span" text={afterHighlightedText} type={type} sx={sharedStyles} color={color} />
    </Typography>
  );
};
