import React, { useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import { AddRounded } from '@mui/icons-material';

import { SamplesGenerationDialog } from './SamplesGenerationDialog/SamplesGeneration';
import { SamplesGenerationPentestDialog } from './SamplesGenerationPentestDialog/SamplesGenerationPentestDialog';

import { StyledText } from '@/components/lib';
import {
  SamplesGenerationNavButton,
  StyledList,
  StyledListItem,
  StyledListItemButton,
  StyledPopover
} from './SamplesGeneration.styes';

import { listApps } from '@/helpers/services/api';
import { isHadron } from '@/helpers/utils/isHadron';
import { getAppStorage } from '@/helpers/utils/localStorage';

import { constants } from './samplesGeneration.constants';

interface SamplesGenerationProps {
  iconsOnly?: boolean;
  isPentestActive: boolean;
}

const { buttonLabel, generatePentestData, generateEvaluationData } = constants;

export const SamplesGeneration = ({ iconsOnly, isPentestActive }: SamplesGenerationProps) => {
  const { appId } = getAppStorage();

  const [curAppId, setCurAppId] = useState(appId);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isPentestDialogOpen, setIsPentestDialogOpen] = useState(false);

  const { query } = useRouter();

  const open = Boolean(anchorEl);
  const isNewAppCreated = query?.newAppCreated;

  const handleOpenDialog = (event: React.MouseEvent<HTMLElement>) => {
    if (isPentestActive) {
      setAnchorEl(event.currentTarget);
    } else {
      setIsDialogOpen(true);
    }
  };

  const handleClose = () => setAnchorEl(null);

  const handleGenerationOpen = (isPentest: boolean) => {
    handleClose();
    if (isPentest) {
      setIsPentestDialogOpen(true);
    } else {
      setIsDialogOpen(true);
    }
  };

  useEffect(() => {
    if (isNewAppCreated) {
      listApps().then(apps => Array.isArray(apps) && setCurAppId(apps[0].id));
    }
  }, [isNewAppCreated]);

  if (isHadron()) {
    return <></>;
  }

  return (
    <>
      <SamplesGenerationNavButton
        disabled={!appId && !isNewAppCreated}
        variant="outlined"
        onClick={handleOpenDialog}
        width={iconsOnly ? '24px' : 'auto'}
        startIcon={!iconsOnly && <AddRounded />}
        label={iconsOnly ? <AddRounded /> : buttonLabel}
      />
      <StyledPopover open={open} anchorEl={anchorEl} onClose={handleClose}>
        <StyledList>
          <StyledListItem onClick={handleGenerationOpen.bind(null, false)}>
            <StyledListItemButton>
              <StyledText text={generateEvaluationData} />
            </StyledListItemButton>
          </StyledListItem>
          <StyledListItem onClick={handleGenerationOpen.bind(null, true)}>
            <StyledListItemButton>
              <StyledText text={generatePentestData} />
            </StyledListItemButton>
          </StyledListItem>
        </StyledList>
      </StyledPopover>
      <SamplesGenerationDialog
        open={isDialogOpen}
        appId={appId || curAppId}
        closeDialog={() => setIsDialogOpen(false)}
      />
      <SamplesGenerationPentestDialog
        appId={appId || curAppId}
        open={isPentestDialogOpen}
        closeDialog={() => setIsPentestDialogOpen(false)}
      />
    </>
  );
};
