import React, { ReactNode, useState } from 'react';

import { ContentCopyOutlined } from '@mui/icons-material';
import { Variant } from '@mui/material/styles/createTypography';
import { Alert, IconButton, Snackbar, Typography, TypographyProps } from '@mui/material';

import { theme } from '../../theme';
import { typographyOptions } from '../../theme/typography';

export type ContrastTypes = 'low' | 'medium' | 'high';

export type TextTypes = 'h1' | 'h2' | 'h3' | 'button' | 'small' | 'body' | 'bodyBold' | 'tiny' | 'tinyBold';

export interface TextProps extends Omit<TypographyProps, 'variant'> {
  text: ReactNode;
  type?: TextTypes;
  includeCopy?: boolean;
  contrast?: ContrastTypes;
  copyOnTopPosition?: boolean;
  component?: React.ElementType;
}

function getTextType(type: TextTypes | undefined): Variant {
  switch (type) {
    case 'h1':
      return 'h1';

    case 'h2':
      return 'h2';

    case 'h3':
      return 'h3';

    case 'button':
      return 'button';

    case 'small':
      return 'h5';

    case 'body':
      return 'body1';

    case 'bodyBold':
      return 'body2';

    case 'tiny':
      return 'subtitle1';

    case 'tinyBold':
      return 'subtitle2';

    default:
      return 'body1';
  }
}

const TEXT_COPIED = 'Text copied to clipboard';

export const Text = (props: TextProps) => {
  const {
    text,
    type,
    color,
    contrast,
    includeCopy = false,
    overflow = 'hidden',
    whiteSpace = 'pre-line',
    copyOnTopPosition = false,
    textOverflow = 'ellipsis',
    component = 'span',
    ...otherProps
  } = props;

  const [snackOpen, setSnackOpen] = useState(false);

  const getContrastColor = (type: TextTypes | undefined, contrast: ContrastTypes | undefined) => {
    const typographyType = typographyOptions[getTextType(type)];
    const contrasts = typographyType?.contrasts as Record<ContrastTypes, string>;

    switch (contrast) {
      case 'low':
        return contrasts?.low;
      case 'medium':
        return contrasts?.medium;
      case 'high':
        return contrasts?.high;
      default:
        return typographyType?.color || '';
    }
  };

  const copyToClipboard = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    setSnackOpen(true);

    navigator?.clipboard?.writeText(text as string);
  };

  return (
    <Typography
      overflow={overflow}
      component={component}
      whiteSpace={whiteSpace}
      textOverflow={textOverflow}
      variant={getTextType(type)}
      sx={{
        position: 'relative',
        color: color || getContrastColor(type, contrast),

        '.MuiButtonBase-root': {
          top: 4,
          right: 4,
          padding: 0,
          width: '28px',
          height: '28px',
          border: 'none',
          display: 'none',
          borderRadius: '6px',
          position: 'absolute',
          color: theme?.palette?.grey[400],
          background: theme?.palette?.grey[200],
          marginTop: copyOnTopPosition ? '-24px' : 0,

          '.MuiSvgIcon-root': {
            opacity: 0.7,
            width: '28px',
            height: '28px',
            padding: '5px',
            margin: '0 auto',
            borderRadius: '6px',

            ':hover': { color: theme?.palette?.grey[500], background: theme?.palette?.grey[300] }
          }
        },

        ':hover': { '.MuiButtonBase-root': { display: 'block' } }
      }}
      {...otherProps}
    >
      {text}
      {includeCopy && (
        <IconButton size="small" onClick={e => copyToClipboard(e)}>
          <ContentCopyOutlined />
        </IconButton>
      )}
      <Snackbar
        open={snackOpen}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => setSnackOpen(false)}
      >
        <Alert
          severity="success"
          variant="filled"
          sx={{ background: theme.palette.success.main, color: theme.palette.common.white }}
        >
          {TEXT_COPIED}
        </Alert>
      </Snackbar>
    </Typography>
  );
};
