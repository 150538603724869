import React, { useState } from 'react';

import { useRouter } from 'next/router';

import { StyledButton, StyledDialogBase, StyledInput, StyledText } from '@/components/lib';
import { CompleteDetailsDialogContainer } from '../Sidebar/UserInfo/UserInfo.styles';

import { getCompleteDetailsApiV1UsersCompleteDetailsGet } from '@/helpers/services/api';

interface CompleteDetailsDialogProps {
  open: boolean;
}

const constants = {
  title: 'Complete Organization Details',
  submitButtonLabel: 'Submit',
  placeholder: 'Inset your org name...'
};

export const CompleteDetailsDialog = ({ open }: CompleteDetailsDialogProps) => {
  const { reload } = useRouter();
  const [orgName, setOrgName] = useState('');

  const handleSubmit = async () => {
    const payload = { organization_name: orgName };

    await getCompleteDetailsApiV1UsersCompleteDetailsGet(payload).then(() => reload());
  };

  return (
    <StyledDialogBase open={open}>
      <CompleteDetailsDialogContainer>
        <StyledText text={constants.title} type="h1" />
        <StyledInput value={orgName} onChange={e => setOrgName(e.target.value)} placeholder={constants.placeholder} />
        <StyledButton label={constants.submitButtonLabel} onClick={handleSubmit} disabled={!orgName} />
      </CompleteDetailsDialogContainer>
    </StyledDialogBase>
  );
};
