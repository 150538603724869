import React, { useState } from 'react';

import { OpenInNewRounded } from '@mui/icons-material';

import { StyledButton, StyledInput, StyledText } from '@/components/lib';

import {
  SamplesGenerationContentContainer,
  StyledDescriptionText,
  StyledLinkContainer
} from '../../SamplesGeneration.styes';
import {
  StyledCategoriesButtonsContainer,
  StyledCategoriesContainer,
  StyledCategoriesListContainer,
  StyledCategoriesListItem,
  StyledCategoriesSearchContainer,
  StyledNextButton,
  sxStyles
} from './CategoriesList.styes';

import { constants } from '../../samplesGeneration.constants';

interface CategoriesListProps {
  handleChangeView: () => void;
  selectedCategories: string[];
  setSelectedCategories: React.Dispatch<React.SetStateAction<string[]>>;
  initialCategoriesList: string[];
}

const {
  generatePentestDescription,
  readMoreBtnLabel,
  selectCategories,
  searchCategories,
  selectAll,
  deselectAll,
  nextBtnLabel
} = constants;

export const CategoriesList = (props: CategoriesListProps) => {
  const { handleChangeView, selectedCategories, setSelectedCategories, initialCategoriesList } = props;
  const [searchFieldValue, setSearchFieldValue] = useState('');

  const handleSearchFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchFieldValue(event.target.value);
    setSelectedCategories([...selectedCategories]);
  };

  const resetSearchField = () => setSearchFieldValue('');

  const handleGoToDocumentation = () =>
    window.open('https://llmdocs.deepchecks.com/docs/pentesting-your-llm-pipeline', '_blank');

  const curInitialCategoriesList = searchFieldValue.length
    ? initialCategoriesList.filter(el => el.toLowerCase().includes(searchFieldValue.toLowerCase()))
    : initialCategoriesList;

  const handleSelectAll = () => setSelectedCategories(initialCategoriesList);

  const handleDeselectAll = () => setSelectedCategories([]);

  const handleItemClick = (item: string) => {
    if (selectedCategories.includes(item)) {
      const filteredSelectedCategories = selectedCategories.filter(el => el !== item);
      setSelectedCategories(filteredSelectedCategories);
    } else {
      setSelectedCategories([...selectedCategories, item]);
    }
  };

  return (
    <SamplesGenerationContentContainer>
      <StyledDescriptionText
        text={
          <>
            {generatePentestDescription}
            <StyledLinkContainer onClick={handleGoToDocumentation}>
              {readMoreBtnLabel}
              <OpenInNewRounded fontSize="small" />
            </StyledLinkContainer>
          </>
        }
        component="span"
        type="tiny"
      />
      <StyledDescriptionText text={selectCategories} type="small" />
      <StyledCategoriesContainer>
        <StyledCategoriesSearchContainer>
          <StyledInput
            placeholder={searchCategories}
            value={searchFieldValue}
            onChange={handleSearchFieldChange}
            onCloseIconClick={resetSearchField}
            searchField
            sx={sxStyles}
          />
          <StyledCategoriesButtonsContainer>
            <StyledButton
              onClick={handleSelectAll}
              label={selectAll(initialCategoriesList.length)}
              disabled={selectedCategories.length === initialCategoriesList.length}
            />
            <StyledButton onClick={handleDeselectAll} label={deselectAll} disabled={!selectedCategories.length} />
          </StyledCategoriesButtonsContainer>
        </StyledCategoriesSearchContainer>
        <StyledCategoriesListContainer>
          {curInitialCategoriesList.map(el => (
            <StyledCategoriesListItem
              key={el}
              data-testid={el}
              selected={selectedCategories.includes(el)}
              onClick={handleItemClick.bind(null, el)}
            >
              <StyledText text={el} type="bodyBold" />
            </StyledCategoriesListItem>
          ))}
        </StyledCategoriesListContainer>
      </StyledCategoriesContainer>
      <StyledNextButton
        onClick={handleChangeView}
        disabled={!selectedCategories.length}
        label={nextBtnLabel(selectedCategories.length)}
      />
    </SamplesGenerationContentContainer>
  );
};
