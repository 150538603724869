import { Theme } from '@mui/material';

export const sxContainerStyles = {
  width: 'auto'
};

export const sxButtonContainerStyles = (isSelected: boolean, theme: Theme) => ({
  flexDirection: 'row',
  alignItems: 'center',
  borderBottom: isSelected ? `3px solid ${theme.palette.primary.main}` : '3px solid transparent',
  '&:hover': {
    borderBottom: isSelected ? `3px solid ${theme.palette.primary.main}` : `3px solid ${theme.palette.grey[400]}`
  },
  margin: '0 12px',
  '&:first-of-type': { margin: '0 12px 0 0' }
});

export const sxButtonStyles = (manyItems: boolean, isSelected: boolean, theme: Theme) => ({
  color: isSelected ? theme.palette.primary.main : theme.palette.grey[400],
  fontSize: '20px !important',
  borderRadius: 0,
  pointerEvents: manyItems && isSelected ? 'none' : 'auto',
  '&:hover': { background: 'none' }
});

export const sxSecondButtonStyles = (
  colorsToUse: (isSelected: boolean) => {
    background: string;
    color: string;
    border: string;
  },
  isSelected: boolean,
  manyItems: boolean,
  connected?: boolean
) => ({
  borderWidth: '1px',
  borderStyle: 'solid',
  padding: '7px 24px',
  transition: '0.5s',
  backgroundColor: colorsToUse(isSelected).background,
  color: colorsToUse(isSelected).color,
  borderColor: colorsToUse(isSelected).border,
  borderRadius: connected ? 0 : '28px',
  pointerEvents: manyItems && isSelected ? 'none' : 'auto',

  ':hover': {
    backgroundColor: colorsToUse(isSelected).background,
    color: colorsToUse(isSelected).color,
    borderColor: colorsToUse(isSelected).border
  },

  '&:last-of-type': { borderRadius: connected ? '0 16px 16px 0' : 'auto' },
  '&:first-of-type': { borderRadius: connected ? '16px 0 0 16px' : 'auto' }
});
