import { Theme } from '@mui/material';

export const sxContainerStyles = (bgColor: string, border?: boolean) => ({
  border: border ? `2px solid ${bgColor}` : 'none',
  borderRadius: '8px'
});

export const sxWrapperStyles = (
  gradientColorStops: string,
  disabled: boolean,
  border: boolean,
  isOnClick: boolean,
  maxWidth?: string
) => ({
  background: `linear-gradient(to right, ${gradientColorStops})`,
  cursor: disabled ? 'not-allowed' : isOnClick ? 'pointer' : 'auto',
  maxWidth: maxWidth ?? 250,
  padding: '12px',
  transform: '0.5s',
  gap: '8px',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: border ? '6px' : '8px',
  opacity: disabled ? 0.5 : 1,

  ':hover': { opacity: 0.7, '.edit-container': { opacity: 1 } }
});

export const sxTextContainerStyles = (theme: Theme) => ({
  gap: '2px',
  opacity: 0,
  alignItems: 'center',
  flexDirection: 'row',
  borderRadius: '4px',

  ':hover': {
    backgroundColor: theme.palette.grey[200],

    '.MuiTypography-root, .MuiSvgIcon-root': { color: theme.palette.primary.main }
  }
});

export const sxIconStyles = (theme: Theme) => ({
  fontSize: '16px',
  color: theme.palette.grey[600]
});
