import { styled, Stack } from '@mui/material';

import { Text } from '../../../Text/Text';

export const StyledContainer = styled(Stack)(({ theme }) => ({
  gap: '8px',
  padding: '4px 8px',
  textAlign: 'center',
  alignItems: 'center',

  '.MuiTypography-subtitle1': { color: theme.palette.grey[500] }
}));

export const StyledLabelText = styled(Text)(({ theme }) => ({
  color: theme.palette.grey[500]
}));

export const StyledScoreText = styled(Text)(({ theme }) => ({
  color: theme.palette.primary.main
}));

export const StyledSeparator = styled(Stack)(({ theme }) => ({
  height: '1px',
  width: '100%',
  backgroundColor: theme.palette.grey[300]
}));

export const StyledRowContainer = styled(Stack)({
  gap: '16px',
  textAlign: 'center',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between'
});
