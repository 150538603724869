import { Keyframes } from '@emotion/react';
import { Palette } from '@mui/material';

export const sxStyles = (
  width: string,
  height: string,
  margin: string,
  borderRadius: string,
  palette: Palette,
  fade: Keyframes
) => ({
  width: width,
  height: height,
  margin: margin,
  borderRadius: borderRadius,
  bgcolor: palette.grey[200],
  animation: `${fade} 0.8s ease`
});
