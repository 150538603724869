import React, { useEffect, useState } from 'react';

import { ClearRounded } from '@mui/icons-material';

import { PromptsList } from './PromptsList/PromptsList';
import { CategoriesList } from './CategoriesList/CategoriesList';

import { StyledDialogBase, StyledText } from '@/components/lib';
import { SamplesGenerationHeaderContainer } from '../SamplesGeneration.styes';

import { getPentestCategoriesApiV1PentestCategoriesGet } from '@/helpers/services/api';

import { constants } from '../samplesGeneration.constants';

interface SamplesGenerationPentestDialogProps {
  open: boolean;
  appId: number;
  closeDialog: () => void;
}

export const SamplesGenerationPentestDialog = (props: SamplesGenerationPentestDialogProps) => {
  const { open, appId, closeDialog } = props;

  const [isViewPrompts, setIsViewPrompts] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [initialCategoriesList, setInitialCategoriesList] = useState<string[]>([]);

  const handleChangeView = () => setIsViewPrompts(!isViewPrompts);

  const handleClose = () => {
    closeDialog();

    setTimeout(() => {
      setIsViewPrompts(false);
      setSelectedCategories([]);
      setInitialCategoriesList([]);
    }, 300);
  };

  useEffect(() => {
    if (open) {
      getPentestCategoriesApiV1PentestCategoriesGet().then(res => setInitialCategoriesList(res));
    }
  }, [open]);

  return (
    <StyledDialogBase open={open} maxWidth="sm">
      <SamplesGenerationHeaderContainer>
        <StyledText text={constants.generatePentestData} type="h1" />
        <ClearRounded onClick={handleClose} color="info" />
      </SamplesGenerationHeaderContainer>
      {isViewPrompts ? (
        <PromptsList selectedCategories={selectedCategories} appId={appId} />
      ) : (
        <CategoriesList
          handleChangeView={handleChangeView}
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
          initialCategoriesList={initialCategoriesList}
        />
      )}
    </StyledDialogBase>
  );
};
