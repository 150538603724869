import React, { ReactNode } from 'react';

import { Button, Stack, SxProps, useTheme } from '@mui/material';

import { Container } from '../../Container/Container';

import { sxButtonContainerStyles, sxButtonStyles, sxContainerStyles, sxSecondButtonStyles } from './Select.styles';

export interface SelectProps {
  selections: { value: string | number; label: string }[];
  state: string | number | undefined | null;
  setState: (state: string | number | undefined | null) => void;
  margin?: string;
  connected?: boolean;
  disabled?: boolean;
  textOnly?: boolean;
  textOnlyEndAdornmentWhenActive?: ReactNode;
  sx?: SxProps;
}

export const Select = (props: SelectProps) => {
  const {
    selections,
    state,
    setState,
    margin = '0',
    connected,
    disabled,
    textOnly,
    textOnlyEndAdornmentWhenActive,
    sx
  } = props;

  const theme = useTheme();
  const manyItems = Number(selections?.length) > 1;

  const handleSelect = (value: string | number) => {
    if (value === state) {
      setState(undefined);
    } else {
      setState(value);
    }
  };

  const colorsToUse = (isSelected: boolean) => {
    switch (isSelected) {
      case true:
        return {
          background: theme.palette.primary.main,
          color: theme.palette.common.white,
          border: theme.palette.primary.main
        };
      case false:
        return {
          background: theme.palette.common.white,
          color: theme.palette.primary.main,
          border: theme.palette.primary.main
        };
      default:
        return {
          background: theme.palette.common.white,
          color: theme.palette.primary.main,
          border: theme.palette.primary.main
        };
    }
  };

  return (
    <Container
      flexDirection="row"
      gap={connected ? 0 : '16px'}
      margin={margin}
      sx={{
        pointerEvents: disabled ? 'none' : 'auto',
        ...sxContainerStyles,
        ...sx
      }}
    >
      {textOnly
        ? selections.map((selection, i) => {
            const isSelected = selection.value === state;

            return (
              <Stack key={i} sx={sxButtonContainerStyles(isSelected, theme)}>
                <Button
                  value={selection.value}
                  onClick={() => handleSelect(selection.value)}
                  variant="text"
                  sx={sxButtonStyles(manyItems, isSelected, theme)}
                  data-testid={
                    isSelected
                      ? `AppFiltersEnvButtonSelected${selection.value}`
                      : `AppFiltersEnvButton${selection.value}`
                  }
                >
                  {selection.label}
                </Button>
                {isSelected && textOnlyEndAdornmentWhenActive}
              </Stack>
            );
          })
        : selections.map((selection, i) => {
            const isSelected = selection.value === state;

            return (
              <Button
                key={i}
                value={selection.value}
                onClick={() => handleSelect(selection.value)}
                sx={sxSecondButtonStyles(colorsToUse, isSelected, manyItems, connected)}
              >
                {selection.label}
              </Button>
            );
          })}
    </Container>
  );
};
