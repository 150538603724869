import { isHadron } from '../utils/isHadron';
import { customInstance } from './customAxios';
import { LogoutApiV1AuthLogoutGetQueryResult } from './api';

export const getAuthUrl = () => {
  let loginRedirectLocation = `${process.env.NEXT_PUBLIC_BASE_API}/api/v1/auth/login/auth0`;

  const redirectSignup = `${process.env.NEXT_PUBLIC_BASE_API}/api/v1/auth/signup/auth0`;
  const isSignUp = typeof window !== 'undefined' && !!window?.location?.search?.includes('signup=true');

  if (typeof window !== 'undefined') {
    const curUrl = window?.location?.href;

    loginRedirectLocation += `?return_uri=${encodeURIComponent(curUrl)}`;

    return isSignUp ? redirectSignup : loginRedirectLocation;
  } else {
    return isSignUp ? redirectSignup : loginRedirectLocation;
  }
};

export const getCustomLogout = () => {
  return customInstance<LogoutApiV1AuthLogoutGetQueryResult>({
    method: 'GET',
    url: `/api/v1/auth/logout`,
    params: isHadron() ? { sm_auth_session_logout: true } : {}
  });
};
