import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

import { comboBarsColors } from './comboChart.helpers';

export const StyledLegendsContainer = styled(Stack)({
  gap: '24px',
  flexDirection: 'row',
  alignItems: 'center',
  margin: '16px auto 8px',
  justifyContent: 'center',

  '.MuiTypography-root': {
    color: comboBarsColors.lightBlue,
    position: 'relative',
    paddingLeft: '16px',

    ':before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      width: '8px',
      height: '8px',
      borderRadius: '50%',
      backgroundColor: 'currentColor'
    },

    ':first-of-type': {
      color: comboBarsColors.blue,
      ':before': {
        backgroundColor: comboBarsColors.blue
      }
    },
    ':last-of-type': {
      color: comboBarsColors.grey,
      ':before': {
        backgroundColor: comboBarsColors.grey
      }
    }
  }
});
