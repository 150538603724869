import React, { ReactNode } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { IconButton, DialogProps as MUIDialogProps } from '@mui/material';

import { Text } from '../Text/Text';
import { Button } from '../Button/Button';
import { DialogBase } from './DialogBase';
import { Container } from '../Container/Container';

import { sxStyles } from './Dialog.styles';

export interface DialogProps extends MUIDialogProps {
  open: boolean;
  title: string;
  onlySave?: boolean;
  isLoading?: boolean;
  onlyCancel?: boolean;
  hideButtons?: boolean;
  submitButtonLabel?: string;
  submitButtonWidth?: string;
  alertTypeButtons?: boolean;
  cancelButtonLabel?: string;
  submitButtonDisabled?: boolean;
  submitButtonStartIcon?: ReactNode;
  children?: ReactNode | ReactNode[];
  closeDialog: () => void;
  submitButtonAction?: () => void;
  cancelButtonAction?: () => void;
}

export const Dialog = (props: DialogProps) => {
  const {
    open,
    title,
    children,
    onlySave,
    isLoading,
    onlyCancel,
    alertTypeButtons,
    submitButtonLabel,
    hideButtons = false,
    submitButtonDisabled,
    submitButtonStartIcon,
    submitButtonWidth = 'auto',
    cancelButtonLabel = 'Cancel',
    closeDialog,
    submitButtonAction,
    cancelButtonAction,
    ...otherProps
  } = props;

  const buttonColor = alertTypeButtons ? 'error' : 'primary';

  return (
    <DialogBase open={open} onClose={closeDialog} {...otherProps}>
      <Container flexDirection="row" justifyContent="space-between" alignItems="center" padding="12px 16px">
        <Text text={title} type="h2" />
        <IconButton onClick={closeDialog} sx={sxStyles}>
          <CloseIcon color={buttonColor} />
        </IconButton>
      </Container>
      <Container paddingX="16px">{children}</Container>
      {!hideButtons && (
        <Container gap="16px" flexDirection="row" justifyContent="center">
          {!onlySave && (
            <Button
              label={cancelButtonLabel}
              onClick={cancelButtonAction || closeDialog}
              variant="outlined"
              color={buttonColor}
            />
          )}
          {!onlyCancel && (
            <Button
              color={buttonColor}
              loading={isLoading}
              label={submitButtonLabel}
              width={submitButtonWidth}
              data-testid="DialogSubmitButton"
              startIcon={submitButtonStartIcon}
              disabled={submitButtonDisabled || isLoading}
              onClick={submitButtonAction}
            />
          )}
        </Container>
      )}
    </DialogBase>
  );
};
