import React from 'react';

import { SxProps } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import { sxStyles } from './Loader.styles';

export type LoaderProps = {
  sx?: SxProps;
};

export const Loader = ({ sx }: LoaderProps) => (
  <Box
    sx={{
      ...sxStyles,
      ...sx
    }}
  >
    <CircularProgress />
  </Box>
);
